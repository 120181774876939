import   axios                    ,
       { AxiosError               }  from 'axios';

import { format                   ,
         parseISO                 }  from 'date-fns';

import { Payment                  ,
         Payment2                 ,
		 Purchase                 ,
		 Item                     }  from '../datas/paymentManagment/actionTypes';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';

import { API_URLS                 }  from './urls';

export interface PostResult {
  code:    number,
  comment: string,
  url:     string
}

export const postSubscription = async ( amount:             number ,
										reductionAmount:    number ,
                                        businessUnitCode:   string ,
                                        productVariantCode: string ,
                                        priceCode:          string ,
                                        bankCode:           string ,
                                        userEmail:          string ,
                                        comment:            string ,
                                        token:              string ): Promise<PostResult> => {

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${token}`,
        'User-Agent-Informations': `${userAgentInformations}`,
        'App-Informations': `${appInformations}`,
    };

    const amountStr = `${amount}`;
    const quantityStr = '1';

    var result:PostResult = {
      code:    0,
      comment: "",
      url:     ""
    };

    try
     {
      const response = await axios.post (
        
                                          `${API_URLS.postPurchase}`,
                              
                                          {
                                            quantityStr        ,
                                            businessUnitCode   ,
                                            productVariantCode ,
                                            priceCode          ,
                                            userEmail          ,
                                            comment
                                          },
                                            
                                          {
                                            headers: headers
                                          }
                                        );

 console.log (response)

      switch ( response.status )
       {
        case 201 : const purchaseId = response.data.id;

		           if ( reductionAmount > 0 )
					{
					 const baseUrl =  `${API_URLS.patchAddSpecialItemToUserCart}`;

                     const url = baseUrl.replace("{id}", `${purchaseId}`);

					 const headers2 = {
                         'Accept': 'application/ld+json',
                         'Content-Type': 'application/merge-patch+json',
                         'Authorization': `Bearer ${token}`,
	                     'User-Agent-Informations': `${userAgentInformations}`,
	                     'App-Informations': `${appInformations}`,
                        };

					 const reductionAmount2 : number = -reductionAmount / 1.2

                     const description   : string = "Réduction prorata abonnement Standard"   // ça ne concerne que l'upgrade Standard vers Premium'
                     const quantityStr   : string = "1"
                     const basePriceStr  : string = reductionAmount2.toFixed();
                     const taxRateStr    : string = "2000"
                     const priceToPayStr : string = `${-reductionAmount}`
                     const totalPriceStr : string = `${-reductionAmount}`

					 try
					  {
					   const response3 = await axios.patch (

                                                            url,

                                                            {
                                                               description   ,
                                                               quantityStr   ,
                                                               basePriceStr  ,
                                                               taxRateStr    ,
                                                               priceToPayStr ,
                                                               totalPriceStr
                                                            },

                                                            {
                                                               headers: headers2
                                                            }
                                                          );
														  
														  
														   console.log (response3)

					  }
                     catch ( err )
					  {
                       const error = err as AxiosError;

                       result = {
                                  code:    -201,
                                  comment: "Erreur réseau",
								  url:     ""
                                };

					   return result;
                      }
					}

                   try
                    {
                     const response2 = await axios.post (
           
                                                          `${API_URLS.postPayment}`,
                              
                                                          {
                                                             amountStr  ,
                                                             purchaseId ,
                                                             bankCode   ,
                                                             userEmail  ,
                                                             comment
                                                          },
                                            
                                                          {
                                                             headers: headers
                                                          }
                                                        );

 console.log (response2)

                     switch ( response2.status )
                      {
                       case 201 : result = {
                                             code:    1 ,
                                             comment: "Informations d'achat et de paiement créées" ,
                                             url:     response2.data.url
                                           };

                                  break;
 
                       default :  result = {
                                             code:     -102 ,
                                             comment: `Erreur lors de la création des informations de l'achat (souscription) ${response2.status}`,
                                             url:      ""
                                           };
                      }
                    }
                   catch ( err2 )
				    {
                     const error2 = err2 as AxiosError;

 console.log (error2)

                     if ( error2.response && error2.response.status )
                           {
                            switch ( error2.response.status )
                             {
                              case 401 : result = {
                                                    code:    -102,
                                                    comment: "Session expirée",
											        url:     ""
                                                  };

								         break;

                              default  : result = {
                                                    code:    -201,
                                                    comment: "Erreur réseau",
                                                    url:     ""
                                                  };
                            }
                          }
                     else {
                           result = {
                                      code:    -201,
                                      comment: "Erreur réseau",
                                       url:     ""
                                    };
                          }
                     }

                    break;

          default : result = {
                                code:     -103 ,
                                comment: `Erreur lors de la création des informations de l'achat (souscription) ${response.status}`,
								url:      ""
                              };
         }

    }  
    catch ( err ) {

        const error = err as AxiosError;

 console.log (error)


        if ( error.response && error.response.status )
              {
               switch ( error.response.status )
                {
                 case 401 : result = {
                                       code:    -102,
                                       comment: "Session expirée",
                                        url:     ""
                                     };

                            break;

                 default :  result = {
                                       code:    -201,
                                       comment: "Erreur réseau",
                                        url:     ""
                                     };
                }
              }
         else {
               result = {
                          code:    -201,
                          comment: "Erreur réseau",
                           url:     ""
                        };
              }
    }


    return result;
};


export const postPayment = async ( amount:             number ,
                                   idOfPurchase:       number ,
                                   bankCode:           string ,
                                   userEmail:          string ,
                                   comment:            string ,
                                   token:              string ): Promise<PostResult> => {

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${token}`,
        'User-Agent-Informations': `${userAgentInformations}`,
        'App-Informations': `${appInformations}`,
    };

    const amountStr = `${amount}`;

    var result:PostResult = {
      code:    0,
      comment: "",
      url:     ""
    };

    const purchaseId: string = `${idOfPurchase}`;

    try {

        const response2 = await axios.post (
           
                                                            `${API_URLS.postPayment}`,
                              
                                                            { 
                                                              amountStr  ,
                                                              purchaseId ,
                                                              bankCode   ,
                                                              userEmail  ,
                                                              comment
                                                            },
                                            
                                                            {
                                                              headers: headers
                                                            }
                                                          );

        switch ( response2.status )
         {
         case 201 : result = {
                                               code:    1 ,
                                               comment: "Informations d'achat et de paiement créées" ,
                                                   url:     response2.data.url
                                             };

                                    break;
 
         default :  result = {
                                               code:     -102 ,
                                               comment: `Erreur lors de la création des informations de l'achat (souscription) ${response2.status}`,
                                                url:      ""
                                             };
        }
    }
    catch ( err2 ) {

                     const error2 = err2 as AxiosError;

                   console.log (error2)

                    if ( error2.response && error2.response.status )
                     {
                      switch ( error2.response.status )
                       {
                        case 401 : result = {
                                              code:    -102,
                                              comment: "Session expirée",
                                               url:     ""
                                            };

                                      break;

                        default :  result = {
                                              code:    -201,
                                              comment: "Erreur réseau",
                                               url:     ""
                                            };
                       }
                     }
                     else {
                           result = {
                                      code:    -201,
                                      comment: "Erreur réseau",
                                       url:     ""
                                    };
                          }
                     }


    return result;
};

export interface PaymentInfos {
  code:     number,
  comment:  string,
  payments: Payment []
}

export const getPaymentInfos = async ( email: string ,
                                       token: string ) : Promise<PaymentInfos>  => {

    const baseUrl  = `${API_URLS.getPaymentInfosByEmail}?`;
    const nomInfo1 = "email=";
    const valInfo1 = encodeURIComponent (email);

    const url = `${baseUrl}${nomInfo1}${valInfo1}`;

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
          'Authorization': `Bearer ${token}`,
          'User-Agent-Informations': `${userAgentInformations}`,
          'App-Informations': `${appInformations}`,
    };

    var result:PaymentInfos = {
      code:      0 ,
      comment:  "" ,
      payments: []
    };

    try {

      const response = await axios.get ( url, { headers: headers } );

      const statut = response.status;

      switch ( statut )
       {
        case 200 : const rawInfos = response.data['hydra:member']

                   let payments: Payment[] = [];

                   if ( Array.isArray (rawInfos) ) 
                    {
                     payments = rawInfos.map ( (info: any) => {

                       return {
                                description:      info.description,
                                amount:           info.amount,
                                date:             info.creationDate  ,
                                meansOfPayment:   info.meansOfPayment,
                                status:           info.paymentStatus,
                                transactionId:    info.transactionId,
                                paymentReference: info.paymentReference
                              };
                       } );
                    }

                   result = {
                              code:    1,
                              comment: "Récupération des informations de paiement de l'utilisateur",
                              payments: payments
                            }

                   break;


        default :  result = {
                              code:    -102,
                              comment: `Erreur lors de la récupération des informations de paiement de l'utilisateur : ${response.status}`,
                              payments: []
                            };
       }
    }
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
              {
               switch ( error.response.status )
                {
                 case 401 : result = {
                                       code:    -102,
                                       comment: "Session expirée",
                                        payments: []
                                     };

                            break;

                 default :  result = {
                                       code:    -201,
                                       comment: "Erreur réseau",
                                        payments: []
                                     };
                }
              }
         else {
               result = {
                          code:    -201,
                          comment: "Erreur réseau",
                           payments: []
                        };
              }
    }

    return result;
  };


export interface DetailledPaymentInfos {
  code:     number,
  comment:  string,
  payments: Payment2 []
}

export const getDetailledPaymentInfos = async ( email: string ,
                                                token: string ) : Promise<DetailledPaymentInfos>  => {

    const baseUrl  = `${API_URLS.getDetailledPaymentInfos}?`;
    const nomInfo1 = "email=";
    const valInfo1 = encodeURIComponent (email);

    const url = `${baseUrl}${nomInfo1}${valInfo1}`;

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
          'Authorization': `Bearer ${token}`,
          'User-Agent-Informations': `${userAgentInformations}`,
          'App-Informations': `${appInformations}`,
    };

    var result:DetailledPaymentInfos = {
      code:      0 ,
      comment:  "" ,
      payments: []
    };

    try {

      const response = await axios.get ( url, { headers: headers } );

      const statut = response.status;

      switch ( statut )
       {
        case 200 : const rawPayments = response.data

                   let payments: Payment2[] = [];

                   for ( let p=0 ; p<rawPayments.length ; p++ )
				    {
					 const rawPayment = rawPayments[p];
					 
					 let purchase:Purchase = {
					 
					   creationDate: rawPayment.purchase.creationDate ,
                       cart:         []
					 }

                     const items = rawPayment.purchase.cart;

                     for ( let i=0 ; i<items.length ; i++ )
				      {
					   let item:Item = {
					 
                           productId:          items[i].productId,
                           productName:        items[i].productName,
                           productCatchword:   items[i].productCatchword,
                           productKind:        items[i].productKind,
                           productVariantId:   items[i].productVariantId,
                           productVariantName: items[i].productVariantName,
                           priceToPay:         items[i].priceToPay,
                           quantity:           items[i].quantity,
                           totalPrice:         items[i].totalPrice 
                        }

					   purchase.cart.push (item)
                      }
					 
					 let payment:Payment2 = {
					 
					   description:      rawPayment.description ,
                       amount:           rawPayment.amount ,
                       date:             rawPayment.creationDate   ,
                       meansOfPayment:   rawPayment.meansOfPayment ,
                       status:           rawPayment.status ,
                       transactionId:    rawPayment.transactionId ,
                       paymentReference: rawPayment.paymentReference ,
                       purchase:         purchase
					 }
					 
					 payments.push (payment)
					}

                   result = {
                              code:    1,
                              comment: "Récupération des informations de paiement de l'utilisateur",
                              payments: payments
                            }

                   break;


        default :  result = {
                              code:    -102,
                              comment: `Erreur lors de la récupération des informations de paiement de l'utilisateur : ${response.status}`,
                              payments: []
                            };
       }
    }
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
              {
               switch ( error.response.status )
                {
                 case 401 : result = {
                                       code:    -102,
                                       comment: "Session expirée",
                                        payments: []
                                     };

                            break;

                 default :  result = {
                                       code:    -201,
                                       comment: "Erreur réseau",
                                        payments: []
                                     };
                }
              }
         else {
               result = {
                          code:    -201,
                          comment: "Erreur réseau",
                          payments: []
                        };
              }
    }

    return result;
  };




export interface PostUrbanTourApplicationFeesResult {
  code:       number,
  comment:    string,
  url:        string,
  purchaseId: number,
}

export const postUrbanTourApplicationFees = async ( destination:        string ,
                                                    price:              number ,
                                                    priceCode:          string ,
                                                    bankCode:           string ,
                                                    productVariantCode: string ,
                                                    userEmail:          string ,
                                                    registrationId:     number ,
                                                    token:              string ): Promise<PostUrbanTourApplicationFeesResult> => {

    console.log ( "postUrbanTourApplicationFees ", destination, price, priceCode, productVariantCode , userEmail, registrationId, token)

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${token}`,
        'User-Agent-Informations': `${userAgentInformations}`,
        'App-Informations': `${appInformations}`,
    };

    const amountStr              = `${price}`;    // 30%
    const quantityStr            = '1';
    const businessUnitCode       = 'TV';
  //  const productVariantCode     = "UBF"      // Urban Tour Application Fees
  //  const priceCode              = "001"
    const comment                = `Acompte de 30% Masterclass Lovelight Urban Tour de ${destination}`     // Pour l'affichage dans le compte du client
    const additionalInformations = `Application fees for Urban Tour registation : ${registrationId}`     // ça déclenche un traitement en plus dans PurchaseCreation


    var result:PostUrbanTourApplicationFeesResult = {
      code:       0,
      comment:    "",
      url:        "",
      purchaseId: 0,
    };

    try
     {
      const response = await axios.post (

                                          `${API_URLS.postPurchase}`,

                                          {
                                            quantityStr            ,
                                            businessUnitCode       ,
                                            productVariantCode     ,
                                            priceCode              ,
                                            userEmail              ,
                                            comment                ,
                                            additionalInformations
                                          },

                                          {
                                            headers: headers
                                          }
                                        );

      console.log (response)

      switch ( response.status )
       {
        case 201 : const purchaseId = response.data.id;

                   try
                    {
                     const response2 = await axios.post (

                                                          `${API_URLS.postPayment}`,

                                                          {
                                                             amountStr  ,
                                                             purchaseId ,
                                                             bankCode   ,
                                                             userEmail  ,
                                                             comment
                                                          },

                                                          {
                                                             headers: headers
                                                          }
                                                        );

                     console.log (response2)

                     switch ( response2.status )
                      {
                       case 201 : result = {
                                             code:       1 ,
                                             comment:    "Informations d'achat et de paiement créées" ,
                                             url:        response2.data.url,
                                             purchaseId: purchaseId
                                           };

                                  break;

                       default :  result = {
                                             code:       -102 ,
                                             comment:    `Erreur lors de la création des informations de l'achat (frais de dossier Urban Tour) ${response2.status}`,
                                             url:        "",
                                             purchaseId: 0
                                           };
                      }
                    }
                   catch ( err2 )
				    {
                     const error2 = err2 as AxiosError;

                     console.log (error2)

                     if ( error2.response && error2.response.status )
                           {
                            switch ( error2.response.status )
                             {
                              case 401 : result = {
                                                    code:       -102,
                                                    comment:    "Session expirée",
											        url:        "",
                                                    purchaseId: 0
                                                  };

								         break;

                              default  : result = {
                                                    code:       -201,
                                                    comment:    "Erreur réseau",
                                                    url:        "",
                                                    purchaseId: 0
                                                  };
                            }
                          }
                     else {
                           result = {
                                      code:       -201,
                                      comment:    "Erreur réseau",
                                      url:        "",
                                      purchaseId: 0
                                    };
                          }
                     }

                    break;

          default : result = {
                                code:       -103 ,
                                comment:   `Erreur lors de la création des informations de l'achat (frais de dossier Urban Tour) ${response.status}`,
								url:        "",
                                purchaseId: 0
                              };
         }

    }
    catch ( err ) {

        const error = err as AxiosError;

        console.log (error)

        if ( error.response && error.response.status )
              {
               switch ( error.response.status )
                {
                 case 401 : result = {
                                       code:       -102,
                                       comment:    "Session expirée",
                                       url:        "",
                                       purchaseId: 0
                                     };

                            break;

                 default :  result = {
                                       code:       -201,
                                       comment:    "Erreur réseau",
                                       url:        "",
                                       purchaseId: 0
                                     };
                }
              }
         else {
               result = {
                          code:       -201,
                          comment:    "Erreur réseau",
                          url   :     "",
                          purchaseId: 0
                        };
              }
    }


    return result;
};
