import   React                 ,  
       { useState              ,
         useEffect             }  from 'react';

import { useNavigate           }  from 'react-router-dom';

import { Dispatch              }  from 'redux';

import { useDispatch           }  from 'react-redux';

import   classNames               from 'classnames';

import { differenceInYears ,
         parse             , 
         isValid           } from 'date-fns';

import   styles                   from './registration_form.module.scss';

import { Field                      }  from '../../../widget/field/field';
import { Select                     }  from '../../../widget/select/select';
import { DateSelector               }  from '../../../widget/date_selector/date_selector';
import { WidgetFrame                }  from '../../../widget/widget_frame/widget_frame';
import { Window                     }  from '../../../widget/window/window';
import { WidgetFrameLabel           }  from '../../../widget/widget_frame/widget_frame_label';
import { WindowLogin                }  from '../../../widget/window/window_login';
import { WindowWithConfirmation     }  from '../../../widget/window/window_with_confirmation';
import { WindowWithUserCreationForm }  from '../../../widget/window/window_with_user_creation_form';
import { Toggle                     }  from '../../../widget/toggle/toggle';
import { TextArea                   }  from '../../../widget/textarea/textarea';
import { AnimatedWaitingIcon        }  from '../../../widget/animated_waiting_icon/animated_waiting_icon';

import { WindowForgottenPassword    }  from '../../login/windows/forgotten_password/forgotten_password';


import { BankChoice            }  from '../../../block/payment/bank_choice';


import { Validation            ,
         checkEmail             ,
         checkEmail2             ,
         checkPassword          ,
         checkPassword2         ,
         checkFirstname        ,
         checkLastname         ,
         checkBirthday         ,
         checkAddress          ,
         checkAddress2         ,
         checkZipCode          ,
         checkCity             ,
         checkCountry          ,
         checkPhoneNumber2     ,
         checkRgpdConsent      }  from '../../../../datas/userManagment/validators';

import { useTypedSelector      }  from '../../../../datas/useTypeSelector';

import { initUser              }  from '../../../../datas/userManagment/actionCreators';

import { initAuthentication          ,
         setLogin                    ,
         setToken                    ,
         countAttempt                }  from '../../../../datas/authenticationManagment/actionCreators';


import { postUrbanTourApplicationFees }   from '../../../../network/api_payment';

import { postUrbanTourRegistration    }   from '../../../../network/api_urban_tour';

import { postUserAuthentication       ,
         getUserInfos                 ,
         postUser                     ,
         patchUserInformations        ,
         getUserEmailValidation       ,
         getUserEmailValidationCheck  }   from '../../../../network/api_user';

import { INTERNAL_LINKS               }  from '../../../../network/urls';

import { postEvent                    }  from '../../../../network/api_events_stats';
import { isTokenValid                 }  from '../../../../network/utils';


import { getCatalogInfos             }  from '../../../../network/api_video';
import { getProductCatalogInfos      }  from '../../../../network/api_product';

import { mergeWithUserCart           ,
         getCart                     }  from '../../../../network/api_cart';

import { setUser             ,
         addPayment                  }  from '../../../../datas/userManagment/actionCreators';

import { setCatalog                  }  from '../../../../datas/videoManagment/actionCreators';

import { setProductCatalog           }  from '../../../../datas/productManagment/actionCreators';

import { setCart                     }  from '../../../../datas/cartManagment/actionCreators';

import { getPaymentInfos             ,
         getDetailledPaymentInfos    }  from '../../../../network/api_payment';

import { postBannedIpAddress         ,
         getBannedIpAddressCheck     }  from '../../../../network/api_banned_ip_addresses';

export interface RegistrationFormProps {
  destination:        string | null;
  depositAmount:      number
  priceCode:          string
  productVariantCode: string
  dates:              string[]
}

export const RegistrationForm = ({ destination        ,
                                   depositAmount      ,
                                   priceCode          ,
                                   productVariantCode ,
                                   dates              }: RegistrationFormProps) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );
  const { cart           } = useTypedSelector((state) => state.cartManagment );
  const { user           } = useTypedSelector((state) => state.userManagment );

  const [ sessionDates                    , setSessionDates                    ] = useState ( dates[0]                                      );
  const [ sex                             , setSex                             ] = useState ( user.sex === "" ? "Aucune réponse" : user.sex   );
  const [ firstname                       , setFirstname                       ] = useState ( user.firstname                                );
  const [ lastname                        , setLastname                        ] = useState ( user.lastname                                 );
  const [ email                           , setEmail                           ] = useState ( user.email                                    );
  const [ phoneNumber                     , setPhoneNumber                     ] = useState ( user.phoneNumber                              );
  const [ password                        , setPassword                        ] = useState ( ''                                            );
  const [ password2                       , setPassword2                       ] = useState ( ''                                            );
  const [ birthday                        , setBirthday                        ] = useState ( user.birthday                                 );
  const [ address                         , setAddress                         ] = useState ( user.address                                  );
  const [ address2                        , setAddress2                        ] = useState ( user.address2                                 );
  const [ zipCode                         , setZipCode                         ] = useState ( user.zipCode                                  );
  const [ city                            , setCity                            ] = useState ( user.city                                     );
  const [ country                         , setCountry                         ] = useState ( user.country === "" ? "France" : user.country );
  const [ identityDocument                , setIdentityDocument                ] = useState ( 'CNI'                           );
  const [ identityDocumentValidity        , setIdentityDocumentValidity        ] = useState ( '01/01/2025'                                  );
  const [ nationality                     , setNationality                     ] = useState ( 'Français'                                    );
  const [ depositConsent                  , setDepositConsent                  ] = useState ( false                                         );
  const [ termsOfServiceConsent           , setTermsOfServiceConsent           ] = useState ( false                                         );
  const [ contactRequest                  , setContactRequest                  ] = useState ( false                                         );

  const [ messageSessionDates             , setMessageSessionDates             ] = useState ('');
  const [ messageSex                      , setMessageSex                      ] = useState ('');
  const [ messageFirstname                , setMessageFirstname                ] = useState ('');
  const [ messageLastname                 , setMessageLastname                 ] = useState ('');
  const [ messageEmail                    , setMessageEmail                    ] = useState ('');
  const [ messagePhoneNumber              , setMessagePhoneNumber              ] = useState ('');
  const [ messagePassword                 , setMessagePassword                 ] = useState ('');
  const [ messagePassword2                , setMessagePassword2                ] = useState ('');
  const [ messageBirthday                 , setMessageBirthday                 ] = useState ('');
  const [ messageAddress                  , setMessageAddress                  ] = useState ('');
  const [ messageAddress2                 , setMessageAddress2                 ] = useState ('');
  const [ messageZipCode                  , setMessageZipCode                  ] = useState ('');
  const [ messageCity                     , setMessageCity                     ] = useState ('');
  const [ messageCountry                  , setMessageCountry                  ] = useState ('');
  const [ messageIdentityDocument         , setMessageIdentityDocument         ] = useState ('');
  const [ messageIdentityDocumentValidity , setMessageIdentityDocumentValidity ] = useState ('');
  const [ messageNationality              , setMessageNationality              ] = useState ('');
  const [ messageDepositConsent           , setMessageDepositConsent           ] = useState ('');
  const [ messageTermsOfServiceConsent    , setMessageTermsOfServiceConsent    ] = useState ('');
  const [ messageContactRequest           , setMessageContactRequest           ] = useState ('');

  const [ userId                          , setUserId                          ] = useState ( 0 );
  const [ userToken                       , setUserToken                       ] = useState ( authentication.token );
 
  const [ showWindow                      , setShowWindow                      ] = useState ( false );
  const [ windowMessage                   , setWindowMessage                   ] = useState ( <></> );
  const [ showWindow2                     , setShowWindow2                     ] = useState ( false );
  const [ windowMessage2                  , setWindowMessage2                  ] = useState ( <></> );
  const [ showWindowUserLogin             , setShowWindowUserLogin             ] = useState ( false );
  const [ showWindowUserCreation          , setShowWindowUserCreation          ] = useState ( false );
  const [ showWindowTokenExpired          , setsShowWindowTokenExpired         ] = useState ( false );
  const [ expiredSession                  , setExpiredSession                  ] = useState ( false );
  const [ showWaitingIcon                 , setShowWaitingIcon                 ] = useState ( false );
  const [ showBankChoice                  , setShowBankChoice                  ] = useState ( false );
  const [ showTermsOfService              , setShowTermsOfService              ] = useState ( false );

  const [ newUserCreation                 , setNewUserCreation                 ] = useState ( user.id === 0 );
  const [ outOfFrance                     , setOutOfFrance                     ] = useState ( destination !== "Nice" );

  const [ isSmallScreen                   , setIsSmallScreen                   ] = useState ( false );

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

  }, [isSmallScreen]);

  useEffect(() => {

    console.log ("useEffect authentication=", authentication, user)

    if ( authentication.token === '' )
     {
      dispatch (initUser())
      reset ()
     }

    postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };

    window.scroll(0, 0);
  }, []);
  
  const init = () => {
  
    setSessionDates                    ( dates[0]                                      );
    setSex                             ( user.sex === "" ? "Aucune réponse" : user.sex   );
    setFirstname                       ( user.firstname                                );
    setLastname                        ( user.lastname                                 );
    setEmail                           ( user.email                                    );
    setPhoneNumber                     ( user.phoneNumber                              );
    setBirthday                        ( user.birthday                                 );
    setAddress                         ( user.address                                  );
    setAddress2                        ( user.address2                                 );
    setZipCode                         ( user.zipCode                                  );
    setCity                            ( user.city                                     );
    setCountry                         ( user.country === "" ? "France" : user.country );
    setIdentityDocument                ( 'CNI'                                         );
    setIdentityDocumentValidity        ( '01/01/2025'                                  );
    setNationality                     ( 'Français'                                    );
  //  setDepositConsent                  ( false                                         );
  //  setTermsOfServiceConsent           ( false                                         );

    setMessageSessionDates             ('');
    setMessageSex                      ('');
    setMessageFirstname                ('');
    setMessageLastname                 ('');
    setMessageEmail                    ('');
    setMessagePhoneNumber              ('');
    setMessagePassword                 ('');
    setMessageBirthday                 ('');
    setMessageAddress                  ('');
    setMessageAddress2                 ('');
    setMessageZipCode                  ('');
    setMessageCity                     ('');
    setMessageCountry                  ('');
    setMessageIdentityDocument         ('');
    setMessageIdentityDocumentValidity ('');
    setMessageNationality              ('');
    setMessageDepositConsent           ('');
//    setMessageTermsOfServiceConsent    ('');
//    setMessageContactRequest           ('');
/*
    setUserId                          ( 0 );
    setUserToken                       ( authentication.token );

    setShowWindow                      ( false );
    setWindowMessage                   ( <></> );
    setShowWindow2                     ( false );
    setWindowMessage2                  ( <></> );
    setShowWindowUserLogin             ( false );
    setShowWindowUserCreation          ( false );
    setExpiredSession                  ( false );
    setShowWaitingIcon                 ( false );
    setShowBankChoice                  ( false );
    setShowTermsOfService              ( false );

    setNewUserCreation                 ( user.id === 0 );
    setOutOfFrance                     ( destination !== "Nice" );
*/
  }

  const reset = () => {

    setSessionDates                    ( dates[0]                                      );
    setSex                             ( "Aucune réponse" );
    setFirstname                       ( "" );
    setLastname                        ( "" );
    setEmail                           ( "" );
    setPhoneNumber                     ( "" );
    setPassword                        ( "" );
    setBirthday                        ( "01/01/1975" );
    setAddress                         ( "" );
    setAddress2                        ( "" );
    setZipCode                         ( "" );
    setCity                            ( "" );
    setCountry                         ( "France" );
    setIdentityDocument                ( "CNI"                                         );
    setIdentityDocumentValidity        ( '01/01/2025'                                  );
    setNationality                     ( 'Français'                                    );
    setDepositConsent                  ( false                                         );
    setTermsOfServiceConsent           ( false                                         );

    setMessageSessionDates             ('');
    setMessageSex                      ('');
    setMessageFirstname                ('');
    setMessageLastname                 ('');
    setMessageEmail                    ('');
    setMessagePhoneNumber              ('');
    setMessagePassword                 ('');
    setMessageBirthday                 ('');
    setMessageAddress                  ('');
    setMessageAddress2                 ('');
    setMessageZipCode                  ('');
    setMessageCity                     ('');
    setMessageCountry                  ('');
    setMessageIdentityDocument         ('');
    setMessageIdentityDocumentValidity ('');
    setMessageNationality              ('');
    setMessageDepositConsent           ('');
    setMessageTermsOfServiceConsent    ('');
    setMessageContactRequest           ('');

    setUserId                          ( 0 );
    setUserToken                       ( authentication.token );

    setShowWindow                      ( false );
    setWindowMessage                   ( <></> );
    setShowWindow2                     ( false );
    setWindowMessage2                  ( <></> );
    setShowWindowUserLogin             ( false );
    setShowWindowUserCreation          ( false );
    setExpiredSession                  ( false );
    setShowWaitingIcon                 ( false );
    setShowBankChoice                  ( false );
    setShowTermsOfService              ( false );

    setNewUserCreation                 ( user.id === 0 );
    setOutOfFrance                     ( destination !== "Nice" );

  }

  const checkEmailBis = async ( email: string ) : Promise<Validation> => {

    var result: Validation = {
      code:    1 ,
      comment: ""
    };

    const logged = ( authentication.login !== "" ) // && ( isTokenValid ( authentication.token ) === true );

    if ( logged )
          {
           if ( authentication.login !== email )
                 {
                  setNewUserCreation (true)
                  result = await checkEmail ( email )
                 }
            else {
                  setNewUserCreation (false)
                 }
          }
     else {
           result = await checkEmail ( email )
          }

   return result;
  }

  const checkSessionDates = ( choosenDates: string ) : Validation => {

      var result: Validation = {
      code:    1 ,
      comment: ""
    };

    if ( choosenDates === "" )
     {
      result.code    = -1;
      result.comment = "La session est manquante";
     }

   return result;
  }

  const checkIdentityDocument = ( doc: string ) : Validation => {

      var result: Validation = {
      code:    1 ,
      comment: ""
    };

    if ( doc === "" )
     {
      result.code    = -1;
      result.comment = "Le type de pièce d'identité est manquant";
     }

   return result;
  }

  const checkIdentityDocumentValidity = ( validity: string ) : Validation => {

    var result: Validation = {
      code:    1 ,
      comment: ""
    };

    if ( validity === "" )
          {
           result.code    = -1;
           result.comment = "Il manque la date de validité.";
          }

     else {
           const parsedDate = parse ( validity, 'dd/MM/yyyy', new Date() );

           if ( isValid (parsedDate) === false )
               {
                result.code    = -2;
                result.comment = "La date saisie n'est pas valide.";
               }
          }

    console.log (     "checkIdentityDocumentValidity" ,  validity, result )

    return result;
  };

  const checkNationality = ( nat: string ) : Validation => {

      var result: Validation = {
      code:    1 ,
      comment: ""
    };

    if ( nat === "" )
     {
      result.code    = -1;
      result.comment = "La nationalité est manquante";
     }

   return result;
  }

  const checkDepositConsent = ( dep : boolean ) : Validation => {

      var result: Validation = {
      code:    1 ,
      comment: ""
    };

    if ( !dep )
     {
      result.code    = -1;
      result.comment = "Validation obligatoire";
     }

   return result;
  }

  const checkTermsOfServiceConsent = ( term : boolean ) : Validation => {

      var result: Validation = {
      code:    1 ,
      comment: ""
    };

    if ( !term )
     {
      result.code    = -1;
      result.comment = "Validation obligatoire";
     }

   return result;
  }

  const handleSessionDatesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setSessionDates    ( e.target.value )

    const retour: Validation = checkSessionDates  ( e.target.value )
    setMessageSessionDates ( retour.comment );
  };

  const handleSexChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setSex    ( e.target.value )

  //  const retour: Validation = checkSex ( e.target.value )
  //  setMessageSex ( retour.comment );
  };

  const handleFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setFirstname    ( e.target.value )

    const retour: Validation = checkFirstname  ( e.target.value )
    setMessageFirstname ( retour.comment );
  };

  const handleLastnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setLastname    ( e.target.value )

    const retour: Validation = checkLastname  ( e.target.value )
    setMessageLastname ( retour.comment );
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setEmail ( e.target.value )

    const response: Validation = await checkEmailBis ( e.target.value )

    if ( response.code === -3)
          {
           setMessageEmail ( '' );
          }
     else {
           setMessageEmail ( response.comment );
          }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPhoneNumber    ( e.target.value )

    const retour: Validation = checkPhoneNumber2  ( e.target.value , user.email )
    setMessagePhoneNumber ( retour.comment );
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword ( e.target.value )

    const response: Validation = checkPassword ( e.target.value )
    setMessagePassword ( response.comment );

    const retour2: Validation = checkPassword2 ( password2, e.target.value )
    setMessagePassword2 ( retour2.comment );
  };

  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword2    ( e.target.value )

    const retour: Validation = checkPassword2 ( e.target.value , password )
    setMessagePassword2 ( retour.comment );
  };

  const handleBirthdayChange = ( newBirthday: string ) => {

    setBirthday    ( newBirthday ) 
    
    const retour: Validation = checkBirthday  ( newBirthday )
    setMessageBirthday ( retour.comment );
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress    ( e.target.value ) 
    
    //const retour: Validation = checkAddress  ( e.target.value )
    //setMessageAddress ( retour.comment );
  };

  const handleAddress2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress2    ( e.target.value ) 
    
    //const retour: Validation = checkAddress2  ( e.target.value )
    //setMessageAddress2 ( retour.comment );
  };
    
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setZipCode    ( e.target.value ) 
    
    //const retour: Validation = checkZipCode  ( e.target.value )
    //setMessageZipCode ( retour.comment );
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCity    ( e.target.value ) 
    
    //const retour: Validation = checkCity  ( e.target.value )
    //setMessageCity ( retour.comment );
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCountry    ( e.target.value )
    
    //const retour: Validation = checkCountry  ( e.target.value )
    //setMessageCountry ( retour.comment );
  };

  const handleIdentityDocumentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setIdentityDocument ( e.target.value )

    const retour: Validation = checkIdentityDocument  ( e.target.value )
    setMessageIdentityDocument ( retour.comment );
  };

  const handleIdentityDocumentValidityChange = ( newIdentityDocumentValidity: string ) => {

    setIdentityDocumentValidity ( newIdentityDocumentValidity )

    const retour: Validation = checkIdentityDocumentValidity  ( newIdentityDocumentValidity )
    setMessageIdentityDocumentValidity ( retour.comment );
  }

  const handleNationalityChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setNationality ( e.target.value )

    const retour: Validation = checkNationality  ( e.target.value )
    setMessageNationality ( retour.comment );
  };

  const handleDepositConsentChange  = ( newDepositConsent: boolean ) => {

    setDepositConsent ( newDepositConsent )
   // dispatch ( updateRgpdConsent ( newRgpdConsent ) )

    const response: Validation = checkDepositConsent ( newDepositConsent )
    setMessageDepositConsent ( response.comment );
  };

  const handleTermsOfServiceConsentChange  = ( newTermsOfServiceConsent: boolean ) => {

    setTermsOfServiceConsent ( newTermsOfServiceConsent )
   // dispatch ( updateRgpdConsent ( newRgpdConsent ) )

    const response: Validation = checkTermsOfServiceConsent ( newTermsOfServiceConsent )
    setMessageTermsOfServiceConsent ( response.comment );
  };

  const handleContactRequestChange = ( val: boolean ) => {

    setContactRequest ( val )
  }

  const handleValidateButtonClick = () => {

    makeProcess ()
  }

  const makeProcess = async () => {

    const check = await checkAll ()

    if ( check )
     {
      makeRegistration ("02")
     }

  }

/*  const makeRegitration = async () => {

    const check = await checkAll ()

    if ( check )
     {
      setShowBankChoice (true);
     }

  }*/

  const checkAll = async (): Promise<boolean> => {
  
    const logged = ( authentication.login !== "" )
    let test:     boolean = false;
    let nextStep: boolean = true;

    if ( logged && isTokenValid ( authentication.token ) === false )
          {
           // VOUS DEVEZ VOUS RECONNECTER
          }
     else {
           const checkEmailResult                 = await checkEmailBis               ( email )
           const checkFirstnameResult             = checkFirstname              ( firstname )
           const checkLastnameResult              = checkLastname               ( lastname )
           const checkBirthdayResult              = checkBirthday               ( birthday )
           const checkPhoneNumberResult           = checkPhoneNumber2           ( phoneNumber , user.email )
           const checkSessionDatesResult          = checkSessionDates           ( sessionDates )
           const checkTermsOfServiceConsentResult = checkTermsOfServiceConsent  ( termsOfServiceConsent )
           const checkDepositConsentResult        = checkDepositConsent         ( depositConsent )

           if ( checkEmailResult.code === -3 )
                 {
                  setMessageEmail ( '' );
                 }
            else {
                  setMessageEmail ( checkEmailResult.comment );
                 }

           setMessageFirstname             ( checkFirstnameResult.comment );
           setMessageLastname              ( checkLastnameResult.comment );
           setMessageBirthday              ( checkBirthdayResult.comment );
           setMessagePhoneNumber           ( checkPhoneNumberResult.comment );
           setMessageSessionDates          ( checkSessionDatesResult.comment );
           setMessageTermsOfServiceConsent ( checkTermsOfServiceConsentResult.comment );
           setMessageDepositConsent        ( checkDepositConsentResult.comment );

           const check1:number = checkEmailResult.code +
                                 checkFirstnameResult.code +
                                 checkLastnameResult.code +
                                 checkBirthdayResult.code +
                                 checkPhoneNumberResult.code +
                                 checkSessionDatesResult.code +
                                 checkTermsOfServiceConsentResult.code +
                                 checkDepositConsentResult.code;

           console.log ("checkAll")
           console.log (" - checkEmailResult                 = ", checkEmailResult)
           console.log (" - checkFirstnameResult             = ", checkFirstnameResult)
           console.log (" - checkLastnameResult              = ", checkLastnameResult)
           console.log (" - checkBirthdayResult              = ", checkBirthdayResult)
           console.log (" - checkPhoneNumberResult           = ", checkPhoneNumberResult)
           console.log (" - checkSessionDatesResult          = ", checkSessionDatesResult)
           console.log (" - checkTermsOfServiceConsentResult = ", checkTermsOfServiceConsentResult)
           console.log (" - checkDepositConsentResult        = ", checkDepositConsentResult)

           let check2:number = 3;

           if ( destination !== "Nice" )
            {
             const checkIdentityDocumentResult         = checkIdentityDocument         ( identityDocument )
             const checkIdentityDocumentValidityResult = checkIdentityDocumentValidity ( identityDocumentValidity )
             const checkNationalityResult              = checkNationality              ( nationality )

             setMessageIdentityDocument         ( checkIdentityDocumentResult.comment );
             setMessageIdentityDocumentValidity ( checkIdentityDocumentValidityResult.comment );
             setMessageNationality              ( checkNationalityResult.comment );

             check2 = checkIdentityDocumentResult.code +
                      checkIdentityDocumentValidityResult.code +
                      checkNationalityResult.code ;

             console.log (" - checkIdentityDocumentResult         = ", checkIdentityDocumentResult)
             console.log (" - checkIdentityDocumentValidityResult = ", checkIdentityDocumentValidityResult)
             console.log (" - checkNationalityResult              = ", checkNationalityResult)
            }
/*
           let check3:number = 3;

           if ( newUserCreation )
            {
             const checkPasswordResult    = checkPassword      ( password )                 // si création nouveau compte
             const checkPassword2Result   = checkPassword2     ( password2 , password )     // si création nouveau compte
             const checkRgpdConsentResult = checkRgpdConsent   ( rgpdConsent )              // si création nouveau compte

             setMessagePassword     ( checkPasswordResult.comment );
             setMessagePassword2    ( checkPassword2Result.comment );
             setMessageRgpdConsent  ( checkRgpdConsentResult.comment );

             check3 = checkPasswordResult.code +
                      checkPassword2Result.code +
                      checkRgpdConsentResult.code ;

             console.log (" - checkPasswordResult    = ", checkPasswordResult)
             console.log (" - checkPassword2Result   = ", checkPassword2Result)
             console.log (" - checkRgpdConsentResult = ", checkRgpdConsentResult)
            }
*/
           test = ( check1 === 8 ) && ( check2 === 3 );

           console.log ("test : ", test?"ok":"non")

           if ( !test )
                 {
                  nextStep = false;

                  if ( check1 === 7-3 )
                   {
                    if ( !logged )
                     {
                      if ( checkEmailResult.code === -3 )
                       {
                        setShowWindowUserLogin (true)
                       }
                     }
                   }

                 }
            else {
                  if ( !logged )
                   {
                    if ( checkEmailResult.code === 1 && test )
                     {
                      setShowWindowUserCreation (true)
                      nextStep = false
                     }
                   }
                 }
          }

    console.log ("nextStep : ", test?"ok":"non")

    return nextStep;
  }
  
  interface UserAuthenticationResult {

    userId           : number,
    userToken        : string,
    code             : number,
    message          : string,
    numberOfAttempts : number
  }

  const makeUserAuthentication = async ( localUserEmail    : string ,
                                         localUserPassword : string ,
                                         isNewUser         : boolean = false ): Promise<UserAuthenticationResult> => {

    let result : UserAuthenticationResult = {

      userId           : 0  ,
      userToken        : '' ,
      code             : 0  ,
      message          : '' ,
      numberOfAttempts : 0
    }

    let localUserToken : string = ''
    let localUserId    : number = 0

    console.log ( "makeUserAuthentication 0 : ", localUserEmail, localUserPassword , isNewUser )

    const bannedIpAddressCheckResult = await getBannedIpAddressCheck ();

    console.log ( "makeUserAuthentication 1 :" , bannedIpAddressCheckResult)

    if ( bannedIpAddressCheckResult.code === 1 || isNewUser )
          {
           dispatch ( countAttempt () );

           const authenticationResult = await postUserAuthentication ( localUserEmail, localUserPassword )

           console.log ("makeUserAuthentication 2 :" , authenticationResult)

           if ( authenticationResult.code === 1 || isNewUser )
                   {
                    localUserToken = authenticationResult.token

                    setUserToken ( localUserToken )

                    dispatch ( setLogin ( localUserEmail ) );
                    dispatch ( setToken ( localUserToken ) );

                    const userEmailValidationCheckResult = await getUserEmailValidationCheck ( localUserEmail ,
                                                                                               localUserToken );

                    console.log ("makeAuthentication 3 :" , userEmailValidationCheckResult)

                    // Pas la peine de tester l'âge du token JWT car il vient d'être créé

                    if ( userEmailValidationCheckResult.code === 1 || isNewUser )
                          {
                           // L'email a été validé

                           const userInfos = await getUserInfos ( localUserEmail ,
                                                                  localUserToken )

                           console.log ("makeAuthentication 4 :" , userInfos)

                           if ( userInfos.code === 1 )
                                 {
                                  localUserId = userInfos.infos.id

                                  setUserId    ( localUserId )

                                  if ( !isNewUser )
                                   {
                                    console.log ("Ce n'est pas un nouvel utilisateur")

                                    dispatch ( setUser ( userInfos.infos.id                    ,
                                                         userInfos.infos.email                 ,
                                                         ""                                    ,
                                                         userInfos.infos.firstname             ,
                                                         userInfos.infos.lastname              ,
                                                         userInfos.infos.sex                   ,
                                                         userInfos.infos.birthday              ,
                                                         userInfos.infos.address               ,
                                                         userInfos.infos.address2              ,
                                                         userInfos.infos.zipCode               ,
                                                         userInfos.infos.city                  ,
                                                         userInfos.infos.country               ,
                                                         userInfos.infos.phoneNumber           ,
                                                         userInfos.infos.status                ,
                                                         userInfos.infos.statusDeadline        ,
                                                         userInfos.infos.nextStatus            ,
                                                         userInfos.infos.nextStatusRequestDate ,
                                                         userInfos.infos.state                 ) )

                                    ///////////////////////////////////////////////////////////////////////////


                                    if ( cart.items.length > 0 )
                                     {
                                      // On a détecté un panier anonyme, on le merge avec le panier du user

                                      await mergeWithUserCart ( localUserEmail ,
                                                                localUserToken ,
                                                                cart           )
                                     }

                                    const userCart = await getCart ( localUserEmail ,
                                                                     localUserToken )

                                    dispatch ( setCart ( userCart.cart ) )


                                    const productCatalogInfos = await getProductCatalogInfos ( );

                                    dispatch ( setProductCatalog ( productCatalogInfos.catalog ) );

                                    ///////////////////////////////////////////////////////////////////////////

                                    const paymentInfos = await getPaymentInfos ( localUserEmail ,
                                                                                 localUserToken )

                                    const detailledPaymentInfos = await getDetailledPaymentInfos ( localUserEmail ,
                                                                                                   localUserToken )

                                    console.log (detailledPaymentInfos)

                                    for ( let p = 0 ; p < paymentInfos.payments.length ; p++ )
                                     {
                                      const payment = paymentInfos.payments[p];

                                      dispatch ( addPayment ( payment ) )
                                     }

                                    ///////////////////////////////////////////////////////////////////////////

                                    let status : number = 0; // Connecté et gratuit

                                    if ( userInfos.infos.status  === "Standard" ) { status = 1 }
                                    if ( userInfos.infos.status  === "Premium"  ) { status = 2 }

                                    const catalogInfos = await getCatalogInfos ( status );

                                    dispatch ( setCatalog ( catalogInfos.catalog ) );
                                   }

                                  result = {

                                    userId           : localUserId                    ,
                                    userToken        : localUserToken            ,
                                    code             : 1                                     ,
                                    message          : 'ok'                                  ,
                                    numberOfAttempts : authentication.numberOfAttempts
                                  }

                                  // Ne pas oublier de faire init si code === 1
                                  // ou de naviguer vers /grille     navigate ("/grille");
                                 }
                            else {
                                  dispatch ( initAuthentication(false) );

                                  result = {
                                    userId           : localUserId                    ,
                                    userToken        : localUserToken            ,
                                    code             : -3                                                       ,
                                    message          : 'Impossible de récupérer les informations de votre compte.' ,
                                    numberOfAttempts : authentication.numberOfAttempts
                                  }

                                  if ( authentication.numberOfAttempts > 5 )
                                   {
                                    postBannedIpAddress ( localUserEmail )
                                   }
                                 }
                          }
                     else {
                           // L'email n'a pas encore été validé

                           dispatch ( initAuthentication(false) );

                           result = {
                             userId           : localUserId                    ,
                             userToken        : localUserToken            ,
                             code             : -2                                                       ,
                             message          : 'Vous devez valider votre adresse e-mail avant de pouvoir accéder à votre compte.' ,
                             numberOfAttempts : authentication.numberOfAttempts
                           }

                           if ( authentication.numberOfAttempts > 5 )
                            {
                             postBannedIpAddress ( email )
                            }
                          }
                   }
              else {
                    // L'authentification a échoué

                    dispatch ( initAuthentication(false) );

                    result = {
                      userId           : localUserId                    ,
                      userToken        : localUserToken            ,
                      code             : -1                                                       ,
                      message          : 'Erreur d\'authentification.' ,
                      numberOfAttempts : authentication.numberOfAttempts
                    }

                    if ( authentication.numberOfAttempts > 5 )
                     {
                      postBannedIpAddress ( email )
                     }

                   }
          }

     else {
           // L'adresse Ip est bannie

           dispatch ( initAuthentication(false) );

           result = {
             userId           : localUserId                    ,
             userToken        : localUserToken            ,
             code             : -4                                                       ,
             message          : 'Trop de tentatives, votre compte est bloqué pendant 1 heure.' ,
             numberOfAttempts : authentication.numberOfAttempts
           }
          }

   console.log ("makeAuthentication FIN :" , result)

   return result;
  }

  const makeUserRegistration = async ( isNewUser: boolean = false, newPassword: string = '' ): Promise<string> => {

    setShowWaitingIcon (true);

    let user_token: string = authentication.token

    console.log ("makeUserRegistration ", isNewUser, newPassword)
    console.log ("makeUserRegistration user_token initial=", user_token)
    console.log ("makeUserRegistration user.id initial="   ,user.id)

    if ( newUserCreation )
     {
      console.log ("makeUserRegistration >> creation d'un nouveau user")

      const response01 = await postUser ( email      ,
                                          newPassword   ,
                                          firstname  ,
                                          lastname   ,
                                          sex        ,
                                          birthday   ,
                                          "Discover" )

      console.log ("postUser=",response01)

      if ( response01.code === 1 )
            {
             // Le compte a pu être créé, on va se connecter automatiquement dessus afin de continuer le processus de paiement
             // avec un token JWT (car la création d'un paiment est à accès restreint)

             const response02 = await makeUserAuthentication ( email , newPassword , isNewUser )

             console.log ("makeUserAuthentication=",response02)

             if ( response02.userId !== 0 )
                   {
                    await patchUserInformations ( response02.userToken    ,
                                                  response02.userId       ,
                                                  firstname     ,
                                                  lastname      ,
                                                  sex           ,
                                                  birthday      ,
                                                  address       ,
                                                  address2      ,
                                                  zipCode       ,
                                                  city          ,
                                                  country       ,
                                                  phoneNumber   );

                    console.log ("C'est ok ", response02.userId , email, response02.userToken )

                    user_token = response02.userToken
                   }
              else {
                    setShowWaitingIcon (false);
                    setWindowMessage ( <>Problème lors de la connexion automatique au nouveau compte</> );
                    setShowWindow    ( true );
                   }
            }
       else {
             setShowWaitingIcon (false);
             setWindowMessage ( <>Problème lors de la création du compte</> );
             setShowWindow    ( true );
            }
     }
     else {
           if ( user_token === '' )
            {
             const response02 = await makeUserAuthentication ( email , password )

             console.log ("makeUserAuthentication=",response02)

             if ( response02.userId !== 0 )
                   {
                    await patchUserInformations ( response02.userToken    ,
                                                  response02.userId       ,
                                                  firstname     ,
                                                  lastname      ,
                                                  sex           ,
                                                  birthday      ,
                                                  address       ,
                                                  address2      ,
                                                  zipCode       ,
                                                  city          ,
                                                  country       ,
                                                  phoneNumber   );

                    console.log ("C'est ok ", response02.userId , email, response02.userToken )

                    user_token = response02.userToken
                   }
              else {
                    setShowWaitingIcon (false);
                    setWindowMessage ( <>Problème lors de la connexion au compte</> );
                    setShowWindow    ( true );
                   }
            }

    }

    setShowWaitingIcon (false);

    console.log ("makeUserRegistration user_token final=", user_token)

    return user_token;
   }

  const makeRegistration = async ( bankCode: string , isNewUser: boolean = false , newPassword: string ='' ) => {
 
    console.log ("makeRegistration", bankCode)

      const eventMessage: string = "Button:\"Enregistrer\":"
                                 + "destination="                +   destination !== null ? ( destination as string) : ""  + ','
                                 + "sessionDates="               +   sessionDates                                          + ','
                                 + "sex="                        +   sex                                                   + ','
                                 + "firstname="                  +   firstname                                             + ','
                                 + "lastname="                   +   lastname                                              + ','
                                 + "email="                      +   email                                                 + ','
                                 + "phoneNumber="                +   phoneNumber                                           + ','
                                 + "birthday="                   +   birthday                                              + ','
                                 + "address="                    +   address                                               + ','
                                 + "address2="                   +   address2                                              + ','
                                 + "zipCode="                    +   zipCode                                               + ','
                                 + "city="                       +   city                                                  + ','
                                 + "country="                    +   country                                               + ','
                                 + "identityDocument="           +   identityDocument                                      + ','
                                 + "identityDocumentValidity="   +   identityDocumentValidity                              + ','
                                 + "nationality="                +   nationality                                           + ','
                                 + "depositConsent="             + ( depositConsent          ? "true":"false" )            + ','
                                 + "termsOfServiceConsent="      + ( termsOfServiceConsent   ? "true":"false" )            + ','
                                 + "contactRequest="             + ( contactRequest          ? "true":"false" )            + ','
                                 + "bankCode="                   +   bankCode

      postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , "" )

      console.log ("postEvent ", eventMessage)

      const user_token = await makeUserRegistration ( isNewUser , newPassword )

      setShowWaitingIcon (true);

       if ( isTokenValid ( user_token ) === true )
            {
             console.log ("token valide")

             const registrationResult = await postUrbanTourRegistration ( destination !== null ? ( destination as string) : "" ,
                                                                          sessionDates               ,
                                                                          sex                        ,
                                                                          firstname                  ,
                                                                          lastname                   ,
                                                                          email                      ,
                                                                          phoneNumber                ,
                                                                          birthday                   ,
                                                                          address                    ,
                                                                          address2                   ,
                                                                          zipCode                    ,
                                                                          city                       ,
                                                                          country                    ,
                                                                          identityDocument           ,
                                                                          identityDocumentValidity   ,
                                                                          nationality                ,
                                                                          contactRequest             )

             console.log ("registrationResult = ", registrationResult)

             switch ( registrationResult.code )
              {
               case 1    :  const paymentResult = await postUrbanTourApplicationFees ( ( destination !== null ? destination as string : '' )          ,
                                                                                       depositAmount * 100                 ,
                                                                                       priceCode             ,
                                                                                       bankCode              ,
                                                                                       productVariantCode    ,
                                                                                       email                 ,
                                                                                       registrationResult.id ,
                                                                                       user_token            )

                            console.log ("paymentResult = ", paymentResult)

                            setShowWaitingIcon (false);

                            if ( paymentResult.code === 1 )
                                  {
                                   console.log ("REDIRECTION VERS ", paymentResult.url)
                                    window.location.href = paymentResult.url;   // On change de page
                                  }
                             else {
                                   setWindowMessage ( <>La demande de paiement a échoué, veuillez recommencer</> );
                                   setShowWindow ( true );

                                  }


                            break;

               default   : setShowWaitingIcon (false);

                           setWindowMessage ( <>Votre demande n'a pas pu être envoyée, veuillez recommancer</> );
                           setShowWindow ( true );
              }
            }
       else {
             setShowWaitingIcon (false);
             setsShowWindowTokenExpired (true)
            }

   }

  const renderComponent = (): JSX.Element => {

    return  <>

         <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400 text-center">
            Préinscription
         </p>
              
               <br/>

               <div className={classNames(styles.form)}>

                 <div className={classNames(isSmallScreen ? styles.layout_sessionDates_B : styles.layout_sessionDates_A)}>

                   <WidgetFrame name               = "Période souhaitée"
                                error              = {messageSessionDates}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Select choices       = { dates }
                                value         = { sessionDates }
                                onChange      = { handleSessionDatesChange }    />

                  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_firstname)}>

                   <WidgetFrame name               = "Prénom"
                                error              = {messageFirstname}
                                   labelTextPosition  = "left"
                                labelBlockPosition = "top"   >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {firstname}
                                onChange      = {handleFirstnameChange} />

                  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_lastname)}>

                   <WidgetFrame name               = "Nom"
                                error              = {messageLastname}
                                   labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {lastname}
                                onChange      = {handleLastnameChange} />

                  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_email)}>

                   <WidgetFrame name               = "Email"
                                error              = {messageEmail}
                                   labelTextPosition  = "left"
                                labelBlockPosition = "top"   >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {email}
                                onChange      = {handleEmailChange} />

                  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_phoneNumber)}>

                   <WidgetFrame name               = "Numéro de téléphone"
                                error              = {messagePhoneNumber}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {phoneNumber}
                                onChange      = {handlePhoneNumberChange} />

                  </WidgetFrame>

                 </div>

              {/*
                  newUserCreation &&
                  (
                   <>
                     <div className={classNames(styles.layout_password)}>

                       <WidgetFrame name               = "Mot de passe"
                                    error              = {messagePassword}
                                    labelTextPosition  = "left"
                                    labelBlockPosition = "top"   >

                          <Field  type          = "text"
                                  placeholder   = ""
                                  value         = {password}
                                  onChange      = {handlePasswordChange} />

                       </WidgetFrame>

                     </div>
                     <div className={classNames(styles.layout_password2)}>

                       <WidgetFrame name               = "Confirmation du mot de passe"
                                    error              = {messagePassword2}
                                    labelTextPosition  = "left"
                                    labelBlockPosition = "top"   >

                          <Field  type          = "text"
                                  placeholder   = ""
                                  value         = {password2}
                                  onChange      = {handlePassword2Change} />

                       </WidgetFrame>

                     </div>

                   </>
                  )
              */}

                 <div className={classNames( isSmallScreen ? styles.layout_birthday_B : styles.layout_birthday_A )}>

                   <WidgetFrame name               = "Date de naissance"
                                error              = {messageBirthday}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <DateSelector value    = {birthday}
                                      onChange = {handleBirthdayChange} />

                  </WidgetFrame>

                 </div>

                 <div className={classNames( isSmallScreen ? styles.layout_gender_B : styles.layout_gender_A )}>

                   <WidgetFrame name               = "Genre"
                                error              = {messageSex}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Select choices       = { [
                                            "Aucune réponse",
                                            "Homme",
                                            "Femme"
                                          ] }
                                value         = { sex }
                                onChange      = { handleSexChange }    />

                  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_address)}>

                   <WidgetFrame name               = "Adresse"
                                error              = {messageAddress}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address}
                                onChange      = {handleAddressChange} />

                  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_address2)}>

                   <WidgetFrame name               = "Complément d'adresse"
                                error              = {messageAddress2}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address2}
                                onChange      = {handleAddress2Change} />

                  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_zipCode)}>

                   <WidgetFrame name               = {isSmallScreen ? "CP":"Code postal"}
                                error              = {messageZipCode}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {zipCode}
                                onChange      = {handleZipCodeChange} />

                  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_city)}>

                   <WidgetFrame name               = "Ville"
                                error              = {messageCity}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {city}
                                onChange      = {handleCityChange} />

                  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_country)}>

                   <WidgetFrame name               = "Pays"
                                error              = {messageCountry}
                                   labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {country}
                                onChange      = {handleCountryChange} />

                  </WidgetFrame>

                 </div>


              {
                outOfFrance &&
                (
                 <>
                 <div className={classNames( isSmallScreen ? styles.layout_identityDocument_B : styles.layout_identityDocument_A )}>

                   <WidgetFrame name               = "Pièce d'identité"
                                error              = {messageIdentityDocument}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Select choices       = { [
                                                    "CNI",
                                                    "Passeport"
                                                  ] }
                                value         = {identityDocument}
                                onChange      = {handleIdentityDocumentChange}    />

                  </WidgetFrame>

                 </div>

                 <div className={classNames( isSmallScreen ? styles.layout_identityDocumentValidity_B : styles.layout_identityDocumentValidity_A )}>

                   <WidgetFrame name               = "Validité de la pièce d'identité"
                                error              = {messageIdentityDocumentValidity}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <DateSelector value         = {identityDocumentValidity}
                                      onChange      = {handleIdentityDocumentValidityChange}
                                      beginningYear = {2000}
                                      endingYear    = {2040}  />

                  </WidgetFrame>

                 </div>

                 <div className={classNames( isSmallScreen ? styles.layout_nationality_B : styles.layout_nationality_A )}>

                   <WidgetFrame name               = "Nationalité"
                                error              = {messageNationality}
                                labelTextPosition  = "left"
                                labelBlockPosition = "top" >

                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {nationality}
                                onChange      = {handleNationalityChange} />

                  </WidgetFrame>

                 </div>
                 </>
                )
              }

                 <div className={ classNames(isSmallScreen ? ( outOfFrance ? styles.layout_contactRequest_B_2 : styles.layout_contactRequest_B_1 ) :
                                                             ( outOfFrance ? styles.layout_contactRequest_A_2 : styles.layout_contactRequest_A_1) ) }>

                    <WidgetFrame error              = {messageContactRequest}
                                 classNameGrid      = {classNames(styles.grid_contactRequest)}
                                 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_contactRequest)}>

                       Je souhaite recevoir de la part du partenaire Groupe Seemore une proposition de transport vers {destination}

                     </WidgetFrameLabel>

                     <Toggle checked        = {contactRequest}
                              onChange       = {handleContactRequestChange}
                             form           = "checkbox" />

                   </WidgetFrame>

                 </div>

                 <div className={classNames(isSmallScreen ? ( outOfFrance ? styles.layout_termsOfServiceConsent_B_2 : styles.layout_termsOfServiceConsent_B_1 ) :
                                                            ( outOfFrance ? styles.layout_termsOfServiceConsent_A_2 : styles.layout_termsOfServiceConsent_A_1 ) )}>

                    <WidgetFrame error              = {messageTermsOfServiceConsent}
                                 classNameGrid      = {classNames(styles.grid_termsOfServiceConsent)}
                                 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_termsOfServiceConsent)}>

                        J'ai pris connaissance et j'accepte&nbsp;

                        <a href      = "#"
                           onClick   = {(e) => {
                                                  e.preventDefault(); // Empêche la navigation
                                                  handleOpenTermsPopup(); // Fonction pour ouvrir le popup
                                               }}
                           className = "text-[#FFFF00] underline cursor-pointer" >les conditions générales de vente </a>

                     </WidgetFrameLabel>

                     <Toggle checked        = {termsOfServiceConsent}
                              onChange      = {handleTermsOfServiceConsentChange}
                             form           = "checkbox" />

                   </WidgetFrame>

                 </div>

                 <div className={classNames(isSmallScreen ? ( outOfFrance ? styles.layout_depositConsent_B_2 : styles.layout_depositConsent_B_1 ) :
                                                            ( outOfFrance ? styles.layout_depositConsent_A_2 : styles.layout_depositConsent_A_1 )  )}>

                    <WidgetFrame error              = {messageDepositConsent}
                                 classNameGrid      = {classNames(styles.grid_depositConsent)}
                                 labelBlockPosition = "right" >

                     <WidgetFrameLabel className = {classNames(styles.text_depositConsent)}>

                       En validant ma pré-inscription, je m’acquitte de 30% de la valeur du séjour soit la somme de {depositAmount} €. Je dispose d'un délai de de rétractation de 48h dans le cadre de la vente de voyage. Je serai contacté par le groupe Seemore pour finaliser mon inscription.

                     </WidgetFrameLabel>

                     <Toggle checked        = {depositConsent}
                              onChange      = {handleDepositConsentChange}
                             form           = "checkbox" />

                   </WidgetFrame>

                 </div>

               {/*
                newUserCreation &&
                (
                 <div className={classNames( outOfFrance ? styles.layout_rgpdConsent_A : styles.layout_rgpdConsent_B )}>

                   <WidgetFrame error              = {messageRgpdConsent}
                                classNameGrid      = {classNames(styles.grid_rgpdConsent)}
                                labelBlockPosition = "right" >

                    <WidgetFrameLabel className = {classNames(styles.text_rgpdConsent)}>

                      En envoyant ce formulaire, j'accepte également les Conditions d’utilisation du site Internet. Nous traitons vos données personnelles conformément à notre Politique de confidentialité Lovelight TV. Vous avez le droit de refuser certaines activités de traitement de vos données personnelles. Consultez la section « Vos droits » de la Politique de confidentialité pour en savoir plus.

                    </WidgetFrameLabel>

                    <Toggle checked        = {rgpdConsent}
                             onChange       = {handleRgpdConsentChange}
                            form           = "checkbox" />

                   </WidgetFrame>

                 </div>
                )
               */}

                 <div className={classNames( isSmallScreen ? ( outOfFrance ? styles.layout_button_B_2 : styles.layout_button_B_1 ) :
                                                             ( outOfFrance ? styles.layout_button_A_2 : styles.layout_button_A_1 )  )}>



                       <div className={classNames(styles.buttons_block)}>
                        <br/>

                        <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleValidateButtonClick}>
                           Je me préinscris
                        </button>
                       </div>
                       <br/>


                 </div>

                            
               </div>


            </>
  }

  const renderTermsOfServiceDescription = (): JSX.Element => {

    return <>

     <div className={classNames(styles.termsOfServiceDiv)}>
     <p className={classNames(styles.termsOfServiceWindow_text, styles.termsOfServiceWindow_titre1)}>
       Conditions générales
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
       Solde en Euros à verser au plus tard 30 jours avant le départ. Le détaillant se réserve le droit, en cas de non-respect du calendrier
       de paiement, de constater l'annulation du contrat par le client et d'exiger sans mise en demeure préalable l'application
       des pénalités d'annulation prévues ci-dessous.
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text, styles.termsOfServiceWindow_titre2)}>
       CONDITION D'ANNULATION
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
       Annulation par le voyageur
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      FRAIS D ANNULATION DU STAGE
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      15% de frais d'annulation
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      moins de 8 semaines avant la date de départ : 30% de frais d'annulation<br/>
      moins de 4 semaines avant la date de départ : 60% de frais d'annulation<br/>
      moins de 3 semaines avant la date de départ : 80% de frais d'annulation<br/>
      au dela 100 % de frais d'annulation
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Frais d'inscription non remboursables : 30€
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Annulation par le détaillant ou l'organisateur
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Important : Le voyage peut être annulé par le détaillant ou l'organisateur si un nombre minimum de 8 participant(s) n'est pas inscrit avant le 06/11/2024.
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Rappel des règles d'annulation par le détaillant ou l'organisateur selon la durée du séjour :
      <ul>
       <li>20 jours avant la date de départ, pour les voyages dont la durée dépasse 6 jours.</li>
       <li>7 jours avant la date de départ, pour les voyages dont la durée est de 2 à 6 jours.</li>
       <li>48 heures avant la date de départ, pour les voyages dont la durée est 2 jours.</li>
      </ul>
      Le Voyageur sera alors remboursé de toutes les sommes qu'il aura pu verser mais ne pourra pas prétendre à une indemnisation.
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Annulation par le voyageur :
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Absence de droit de rétractation : Conformément aux articles L.221-2 et L.221-28 du Code de la consommation, le présent contrat n'est pas soumis à un droit de rétractation.
      <br/>
      Toutefois, le voyageur peut résoudre le contrat à tout moment avant le début du voyage et devra s'acquitter des frais d'annulation/résolution du contrat.
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      LE PRIX NE COMPREND PAS
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Les dépenses personnelles, les excursions et activités réservées par vos soins auprès de prestataires locaux.
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Révision du prix (à la hausse ou à la baisse)
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Conformément aux articles L.211-12, R. 211-8 et R. 211-9 du Code du tourisme, les prix prévus au contrat sont révisables à la hausse comme à la baisse pour tenir compte des variations du coût des transports (carburant/énergie), des redevances et taxes et des taux de change. Vous serez informé de toute hausse du prix total du forfait, au plus tard 20 jours avant le départ. Cette hausse s'appliquera intégralement sur la part du prix concernée :
      <ul>
       <li>Part du transport : Aucune</li>
       <li>Redevances et taxes connues à la date du contrat : Aucune</li>
       <li>Part des achats en devises : Aucune</li>
       <li>Taux de référence : Aucun</li>
      </ul>
      Pour toute hausse supérieure à 8%, vous recevrez sur un support durable le détail de la variation du prix, ses conséquences sur le prix du forfait, le choix qui s'offre à vousd'accepter ou de refuser dans un délai raisonnable et des conséquences de l'absence de réponse.
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      ASSURANCES
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Formule Statut Descriptif
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      MULTIRISQUES REFUSEE Assurance proposée mais refusée<br/>
      RAPATRIEMENT REFUSEE Assurance proposée mais refusée<br/>
      ANNULATION REFUSEE Assurance proposée mais refusée<br/>
      Si vous justifiez d'une garantie antérieure pour les risques couverts par l'assurance souscrite, vous avez la possibilité de renoncer sans frais à cette/ces assurance(s) dans un
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      ASSURANCES
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      Statut :Confirmé
     </p>

     <p className={classNames(styles.termsOfServiceWindow_text)}>
      délai de 14 jours à compter de sa conclusion et tant qu'aucune garantie n'a été mise en oeuvre.<br/>
      Conformément à l’article L112-2 du code des assurances et en cas de souscription à une assurance couvrant le risque de non-vie, le détaillant fournit au souscripteur un document d'information normalisé sur le produit d'assurance élaboré par le concepteur du produit, dans des conditions définies par décret en Conseil d'Etat.
     </p>

     <div className={classNames(styles.buttons_block)}>
       <br/>

       <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
       onClick   = {handleCloseTermsPopup}>
          Fermer
       </button>
     </div>

     </div>

     <br/>
    </>
  }

  const handleCloseButtonClick = () => {

    setWindowMessage ( <></>);
    setShowWindow ( false );
  }

  const handleCloseButtonClick2 = () => {

    setWindowMessage2 ( <></>);
    setShowWindow2 ( false );
    navigate ( `${INTERNAL_LINKS.Home}` );
  }

  const handleOpenTermsPopup = () => {

    setShowTermsOfService (true)
  }

  const handleCloseTermsPopup = () => {

    setShowTermsOfService (false)
  }

  const handleCancelButtonClickInBankChoice = () => {

    setShowBankChoice (false)
  }

  const handleValidButtonClickInBankChoice = ( codeBank: string ) => {

    setShowBankChoice (false)

    makeRegistration ( codeBank ) // "01" = "BNP - Axepta"
  }

  const handleCloseButtonUserLogin = () => {

    setShowWindowUserLogin (false)
    setsShowWindowTokenExpired (false)
  }


  /*
  const handleValidButtonUserLogin = async ( passwordInWindow: string ) => {

    setsShowWindowTokenExpired (false)

    console.log ("handleValidButtonUserLogin", passwordInWindow)

    const result = await makeUserAuthentication ( email , passwordInWindow )

    if ( result.userToken !== '' )
     {
      setNewUserCreation (false)
      makeRegistration ( "02" ) // "01" = "BNP - Axepta"    setShowBankChoice (true)
     }

  }
  */

  const handleValidButtonUserLogin = async ( result: UserAuthenticationResult ) => {

    setsShowWindowTokenExpired (false)

    console.log ("handleValidButtonUserLogin ----", result)

 //   const result = await makeUserAuthentication ( email , passwordInWindow )

    if ( result.code === 1 )
     {
      init ();
      setNewUserCreation (false)
      makeRegistration ( "02" ) // "01" = "BNP - Axepta"    setShowBankChoice (true)
     }

  }

  const handleCancelButtonInUserCreation = () => {

    setShowWindowUserCreation (false)
  }

  const handleValidButtonInUserCreation = (newPassword: string) => {

    setShowWindowUserCreation (false)
    setPassword (newPassword)
    makeRegistration ( "02" , true , newPassword ) // "01" = "BNP - Axepta"    setShowBankChoice (true)
  }

  return (
            
           <div className={classNames(styles.root)}>

               { ( showWaitingIcon ) && ( <AnimatedWaitingIcon/>) }
               
               {
                showWindow  &&
                (
                  < Window message              = {windowMessage}
                           onClickOnCloseButton = {handleCloseButtonClick} />
                )
               }

               {
                showWindow2  &&
                (
                  < Window message              = {windowMessage2}
                           onClickOnCloseButton = {handleCloseButtonClick2} />
                )
               }

               {
                showWindowUserLogin  &&
                (
                  < WindowLogin message               = {<>Il existe déjà un compte associé à l'adresse {email}, vous pouvez vous connecter pour continuer ou changer d'adresse email.</>}
                                email                 = {email}
                                onClickOnCancelButton = { handleCloseButtonUserLogin }
                                onClickOnValidButton  = { handleValidButtonUserLogin }
                                cancelButtonName      = "Changer l'adresse email"
                                validButtonName       = "Je procède au règlement"
                                stylesGridInfo        = {classNames(styles.grid_info)}
                                eyeVisible            = {true}
                                eyeColor              = "#FFFFFF"
                                makeAuthentication = {makeUserAuthentication} />
                )
               }

               {
                showWindowTokenExpired  &&
                (
                  < WindowLogin message               = {<>Le token a expiré, vous devez vous reconnecter pour continuer.</>}
                                email                 = {email}
                                onClickOnCancelButton = { handleCloseButtonUserLogin }
                                onClickOnValidButton  = { handleValidButtonUserLogin }
                                cancelButtonName      = "Annuler"
                                validButtonName       = "Je procède au règlement"
                                stylesGridInfo        = {classNames(styles.grid_info)}
                                eyeVisible            = {true}
                                eyeColor              = "#FFFFFF"
                                makeAuthentication = {makeUserAuthentication} />
                )
               }

               {
                showWindowUserCreation  &&
                (
                  < WindowWithUserCreationForm email                 = {email}
                                               onClickOnCancelButton = {handleCancelButtonInUserCreation}
                                               onClickOnValidButton  = {handleValidButtonInUserCreation}
                                               eyeVisible            = {true}
                                               eyeColor              = "#FFFFFF"/>
                )
               }

               {
                showTermsOfService &&
                (
                 <Window message   = {renderTermsOfServiceDescription()}
                         className = {classNames(styles.termsOfServiceWindow)} />
                )
               }

               { renderComponent() }

               {
                  showBankChoice
                  &&
                  (
                   < BankChoice onCancelButtonClick = { handleCancelButtonClickInBankChoice }
                                onValidButtonClick  = { handleValidButtonClickInBankChoice  } />
                  )
               }

           </div>
           
         );
};


export default RegistrationForm;
