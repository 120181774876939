import   React                 ,  
       { useState              ,
	     useEffect             }  from 'react';

import { useNavigate           ,
         useLocation            } from 'react-router-dom';

import { Dispatch              }  from 'redux';

import { useDispatch           }  from 'react-redux';

import { parse, differenceInYears, isValid } from 'date-fns';

import   classNames               from 'classnames';

import   styles                   from './urban_tour_registration.module.scss';

import { useTypedSelector      }  from '../../../../datas/useTypeSelector';

import { GlobalMenu       }  from '../../../menu/global/global';
import { Footer           }  from '../../../menu/footer/footer';

import { TopShifter         }  from '../../../menu/top_shifter/top_shifter';
import { CookiesManager     }  from '../../../cookie/manager';
import { Window             }  from '../../../widget/window/window';
import { WindowWithForm     }  from '../../../widget/window/window_with_form';
import { WindowWithField    }  from '../../../widget/window/window_with_field';

//import { PdfViewer   }  from '../../../widget/pdf_viewer/pdf_viewer';

import { RegistrationForm }  from './registration_form'

import { INTERNAL_LINKS   }  from '../../../../network/urls';

import { postEvent        }  from '../../../../network/api_events_stats';
import { isTokenValid     }  from '../../../../network/utils'; 

import { initCart           }  from '../../../../datas/cartManagment/actionCreators';
import { initAuthentication }  from '../../../../datas/authenticationManagment/actionCreators';
import { initUser               }  from '../../../../datas/userManagment/actionCreators';
import { postInformationRequest }  from '../../../../network/api_urban_tour';


export const UrbanTour_Registration = () => {

  const navigate                = useNavigate();
  const dispatch: Dispatch<any> = useDispatch()

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment           );
  
  const [ isSmallScreen         , setIsSmallScreen      ] = useState<boolean>(false);

  const [ alertWindowVisible , setAlertWindowVisible ] = useState ( false );
  const [ alertWindowDeprecatedTokenVisible, setAlertWindowDeprecatedTokenVisible] = useState ( false );

  const [ alertInformationRequestWindowVisible , setAlertInformationRequestWindowVisible ] = useState ( false );

  const location    = useLocation();
  const params      = new URLSearchParams(location.search);
  
  const destination = params.get('destination'); // Récupère la valeur de 'destination'

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

  }, [isSmallScreen]); 


  useEffect(() => {

/*
    let status : number = -1; // Non connecté

    if ( ( authentication.login !== ""   &&
           authentication.token !== "" ) )
     {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    showAlertWindowDeprecatedToken ()
	   }

      status = 0;

      if ( user.status  === "Standard" )  { status = 1 }
      if ( user.status  === "Premium"  )  { status = 2 }
     }

    if ( status !== -1)
          {
		   if ( isTokenValid ( authentication.token ) !== false )
		    {
	         postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

             window.addEventListener('resize', handleResize);

             handleResize();

             return () => {
                window.removeEventListener('resize', handleResize);
             };
	
	         window.scroll(0, 0);
			}
          }
     else {
	       showAlertWindow ()
          }
*/

	         postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

             window.addEventListener('resize', handleResize);

             handleResize();

             return () => {
                window.removeEventListener('resize', handleResize);
             };
	
	         window.scroll(0, 0);

  }, []); 

  const handleInformationRequest = () => {

    const eventMessage: string = "UrbanTour-Registration-Part01-Button01:\"Demander la brochure\""

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertInformationRequestWindowVisible ( true );
  }


  const closeAlertInformationRequestWindowWithCancelButton = () => {

    setAlertInformationRequestWindowVisible ( false );
  }

  const closeAlertInformationRequestWindowWithValidButton = ( firstname:   string ,
                                                              lastname:    string ,
                                                              age:         number ,
                                                              email:       string ,
                                                              phoneNumber: string ,
                                                              city:        string ) => {

    setAlertInformationRequestWindowVisible ( false );

    postInformationRequest ( firstname, lastname, age, email, phoneNumber, city,  "Lovelight Urban Tour spécial " + ( destination === null ? "" : ( destination as string ) ) )
  }

{/*
  const handleDetails = () => {

    const eventMessage: string = "UrbanTour-Registration-Button01:\"Tarif et détails du séjour\":" + destination 

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    window.open ( getPdfUrl (), '_blank');
  }
*/}

  const renderBlockSup = (): JSX.Element => {

    return (
	  <>
		 <div className={classNames(styles.block_sup)}>

		   <div className={classNames(styles.block_sup1)}>
   		     <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
              Tarif
             </p>

             <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
               {getPrice()} € TTC par personne
             </p>
           </div>

		   <div className={classNames(styles.block_sup2)}>
		     <div className={classNames(styles.btn)}>

                <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                        onClick   = {handleInformationRequest}>
                    Télécharger la brochure
                </button>


             </div>
           </div>

         </div>
	  </>
    )
  }

  const renderBlockInf = (): JSX.Element => {

    return (
	  <>
         <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
            Informations complémentaires
         </p>

         <br/>

         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              L’ensemble de la Lovelight Masterclass Urban Tour {destination} est commercialisé par notre partenaire :
         </p>
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              <a href="https://www.seemore.fr/" target="_blank" rel="noopener noreferrer" className = "text-[#FFFF00] underline cursor-pointer">Groupe Seemore</a> - 11 avenue Guillabert - 06600 Antibes<br/>
              tél : 04 93 34 62 50 / mail : antibes@seemore.fr<br/>
              SARL - Capital : 99 092 Euros<br/>
		      Licence Immatriculation : 006120047<br/>
              APE 7911Z - SIREN 036620250 - TVA INTRA FR17036620250<br/>
              Garantie Financière : 300 500 Euros - APST - 15 ave Carnot - 75017 PARIS
         </p>
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce formulaire de préinscription sera directement transmis à notre partenaire Antibes Voyage (Group Seemore)
		      qui vous transmettra un lien d’accès aux conditions générales de vente, à la validation de votre contrat par
		      signature électronique et le lien pour procéder au règlement de votre Masterclass.
         </p>
	  </>
    )
  }

  const renderDestination_Nice = (): JSX.Element => {
  
    return (
	  <>
	    <div className={classNames(styles.description)} >
		 
         <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400 text-center">
            Urban Tour Nice
         </p>
		 
         <br/>

		 <img src="/images/urbantour/Nice.jpg" alt="Nice"  className={classNames(styles.image)} />
		 

		 {renderBlockSup()}


		 
		 <br/>
		 
            <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce prix comprend :
<ul className="list-disc list-inside mt-4"> 
               <li>La Masterclass Lovelight Urban Tour (3 jours)</li>
               <li>Cocktail VIP de bienvenue</li>
			   <li>2 nuits en hôtel 4* chambre single + petits-déjeuners</li>
               <li>Assurance annulation</li>
               <li>1 déjeuner + 1 diner avec l'équipe Lovelight</li>
			  </ul>
            </p>

		 <br/>

            <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce prix ne comprend pas :
<ul className="list-disc list-inside mt-4"> 
               <li>le transport à destination de Nice (En option sur demande auprès de notre partenaire Groupe Seemore)</li>
               <li>les transferts aéroport (En option sur demande auprès de notre partenaire Groupe Seemore)</li>
               <li>les dépenses personnelles</li>
               <li>les repas et boissons (hors petit-déjeuner et repas précisés dans le programme)</li>
			  </ul>
            </p>

		 <br/>
{/*}
		 <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
            Tarifs
         </p>
         <br/>
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
           1290 € TTC par personne
           <br/>
           Inclus dans le forfait
           <ul>
              <li>La Masterclass Lovelight Urban Tour (sur 3 jours)</li>
              <li>2 nuits en formule Petit Déjeuner à l’hôtel 4* WindsoR en chambre individuelle</li>
              <li>1 Diner avec l’équipe de Lovelight</li>
              <li>1 Déjeuner "spécialité niçoise"</li>
              <li>Assurance multirisque (contrat individuel)</li>
           </ul>
           <br/>
           Non inclus dans le forfait
           <ul>
              <li>Déjeuners et diners (hors diner jour 1 et déjeuner jour 3)</li>
           </ul>
           <br/>
           En option avec notre partenaire Seemore Voyages
           <ul>
              <li>transports aller-retour vers Nice</li>
              <li>transferts aller-retour Aéroport Nice Côte d’Azur / Hôtel WindsoR</li>
           </ul>
         </p>


         */}
         <br/>
         <br/>

        {renderBlockInf()}
		
        </div>		
	  </>
    )
  }

//		<PdfViewer url="https://custom-front.preprod.lovelightfactory.fr/pdf/Urban_Brochure_Nice.pdf"/>


  const renderDestination_Rome = (): JSX.Element => {
  
    return  (
	  <>
	    <div className={classNames(styles.description)} >
		 
         <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400 text-center">
            Urban Tour Rome
         </p>
		 
         <br/>

		 <img src="/images/urbantour/Rome.jpg" alt="Rome"  className={classNames(styles.image)} />

		 {renderBlockSup()}

		 <br/>
		 
            <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce prix comprend :
<ul className="list-disc list-inside mt-4"> 
               <li>La Masterclass Lovelight Urban Tour (4 jours)</li>
               <li>Cocktail VIP de bienvenue</li>
			   <li>4 nuits en hôtel 4* chambre single + petits-déjeuners</li>
               <li>Assurance annulation</li>
               <li>1 déjeuner + 1 diner avec l'équipe Lovelight</li>
			  </ul>
            </p>

		 <br/>

            <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce prix ne comprend pas :
<ul className="list-disc list-inside mt-4">
               <li>le transport à destination de Rome (En option sur demande auprès de notre partenaire Groupe Seemore)</li>
               <li>les transferts aéroport (En option sur demande auprès de notre partenaire Groupe Seemore)</li>
               <li>les dépenses personnelles</li>
               <li>les repas et boissons (hors petit-déjeuner et repas précisés dans le programme)</li>
			  </ul>
            </p>

		 <br/>

         {/*
		 <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
            Tarifs
         </p>
         <br/>
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
           1790 € TTC par personne
           <br/>
           Inclus dans le forfait
           <ul>
              <li>La Masterclass Lovelight Urban Tour (sur 4 jours)</li>
              <li>4 nuits en formule Petits-déjeuners à l’hôtel 9Hotel Cesari 4* en chambre individuelle</li>
              <li>Diner d’accueil jour 1</li>
              <li>Déjeuner jour 5</li>
              <li>Transports journaliers aller-retour vers les sites touristiques séléctionnés par Lovelight</li>
              <li>Assurance multirisque (contrat individuel)</li>
           </ul>
           <br/>
           Non inclus dans le forfait
           <ul>
              <li>Déjeuners et diners (hors diner jour 1 et déjeuner jour 5)</li>
           </ul>
           <br/>
           En option avec notre partenaire Seemore Voyages
           <ul>
              <li>transports aller-retour vers Rome</li>
              <li>transferts aller-retour Aéroport Rome Fiumiciano / Hôtel 9Hotel Cesari</li>
           </ul>
         </p>
         */}
         <br/>
         <br/>

        {renderBlockInf()}

        </div>		
	  </>
    )
  }

  const renderDestination_Nowhere = (): JSX.Element => {
  
    return  (
	  <>
	    <span style={{color: '#FFFFFF'}}>DESTINATION INCONNUE</span>
	  </>
    )
  }

  const renderDestination = (): JSX.Element => {
  
    switch ( destination )
	 {
	  case encodeURIComponent ("Nice") : return renderDestination_Nice ()
	                break;
					
	  case encodeURIComponent ("Rome") : return renderDestination_Rome ()
	                break;
					
	  default :     return renderDestination_Nowhere ()
	                break;
	 }
  }

  const getSessionDates = (): string [] => {
  
    let tab: string [] = []
  
    switch ( destination )
	 {
	  case "Nice" : tab.push ("du 28/03/2025 au 30/03/2025")
	                break;
					
	  case "Rome" : tab.push ("du 05/03/2025 au 09/03/2025")
	                tab.push ("du 24/04/2025 au 27/04/2025")
	                break;
					
	  default :     break;
	 }
	 
    return tab
  }

  const getPrice = () : number => {

    let price: number = 0

    switch ( destination )
	 {
	  case "Nice" : price = 1290
	                break;

	  case "Rome" : price = 1790
	                break;

	  default :     break;
	 }

    return price
  }

  const getDepositAmount = () : number => {

    return getPrice() * 0.3
  }

  const getProductId = () : string => {

    let productId: string = "0"

    switch ( destination )
	 {
	  case "Nice" : productId = "12"
	                break;

	  case "Rome" : productId = "13"
	                break;

	  default :     break;
	 }

    return productId
  }

  const getProductVariantId = () : string => {

    let productVariantId: string = "0"

    switch ( destination )
	 {
	  case "Nice" : productVariantId = "14"
	                break;

	  case "Rome" : productVariantId = "15"
	                break;

	  default :     break;
	 }

    return productVariantId
  }

  const getPdfUrl = (): string => {
  
    switch ( destination )
	 {
	  case "Nice" : return "https://custom-front.preprod.lovelightfactory.fr/pdf/Brochure_Urban_Tour_Nice.pdf"
	                break;
					
	  case "Rome" : return "https://custom-front.preprod.lovelightfactory.fr/pdf/Brochure_Urban_Tour_Rome.pdf"
	                break;
					
	  default :     return ""
	 }
  }

  const renderBigSizeComponent = (): JSX.Element => {

    return  <>

              <div className={classNames(styles.layout_1)}>
                <div className={classNames(styles.layout_1_block_text)} >
                   {renderDestination()}
				</div>
                <div className={classNames(styles.layout_1_block_form)} >
                   <RegistrationForm destination        = {destination}
                                     depositAmount      = {getDepositAmount()}
				                     priceCode          = "002"
                                     productVariantCode = {getProductVariantId()}
				                     dates              = {getSessionDates()} />
				</div>
			  </div>

	        </>
  }

  const renderSmallSizeComponent = (): JSX.Element => {

    return  <>
	          
              <div className={classNames(styles.layout_2)}>
                <div className={classNames(styles.layout_2_block_text)} >
                   {renderDestination()}
				</div>
                <div className={classNames(styles.layout_2_block_form)} >
                   <RegistrationForm destination        = {destination}
                                     depositAmount      = {getDepositAmount()}
                                     priceCode          = "002"
                                     productVariantCode = {getProductVariantId()}
				                     dates              = {getSessionDates()} />
				</div>
			  </div>
	
	        </>
  }



  const showAlertWindow = () => {
  
  /*
    if ( authentication.token !== '' )
	      {
		   if ( isTokenValid ( authentication.token ) !== false )
            {
             const eventMessage: string = `UrbanTour-Registration-Window:\"Vous devez être un abonné standard ou premium pour accéder à cette masterclass\"`

             postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
			 
             setAlertWindowVisible ( true );
			}
	      }
	 else {
           const eventMessage: string = `UrbanTour-Registration-Window:\"Vous devez être connecté pour accéder à cette masterclass\"`

           postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
		   
           setAlertWindowVisible ( true );
	      }
  */

    if ( authentication.token !== '' )
	      {
           const eventMessage: string = `UrbanTour-Registration-Window:\"Vous devez disposer d’un compte gratuit\"`

           postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
		   
           setAlertWindowVisible ( true );
	      }
  
  }

/*
  const closeAlertWindowWithCancelButton = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_NON`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowVisible ( false );
  }

  const closeAlertWindowWithValidButton1 = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Login}`)
  }

  const closeAlertWindowWithValidButton2 = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.AccountManagment_Subscription}`)
  }
*/

  const closeAlertWindowWithCreateAccountButton = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_CREATE_ACOUNT`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Subscription}`)
  }

  const closeAlertWindowWithLoginButton = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_LOGIN`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Login}`)
  }



  const showAlertWindowDeprecatedToken = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window:\"Votre session a expiré, vous devez vous reconnecter.\"`

    postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( true );
  }

  const closeAlertWindowDeprecatedTokenWithCancelButton = () => {

    const eventMessage: string = `UrbanTour-Registration-Window-Button_NON`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( false );
	
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Home}` );
   }

  const closeAlertWindowDeprecatedTokenWithValidButton = () => {

    const eventMessage: string = `UrbanTour-Registration-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( false );

	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Login}` );
  }

  function calculateAge(): string {
  
    const birthDate = parse(user.birthday, 'dd/MM/yyyy', new Date());
 
    if (!isValid(birthDate)) 
	 {
      throw new Error("Le format de la date de naissance est invalide.");
     }

    const age = differenceInYears(new Date(), birthDate);
  
    return age.toString();  
  }

  return (
            
            <>
		      
 		      <CookiesManager />
 			  <GlobalMenu />
              <TopShifter/>

	          {/*
	            alertWindowVisible &&
		        authentication.token === '' &&
	            ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être connecté pour accéder à cette offre.</span><br/><br/>Se connecter ?<br/></> }
                           messageType           = "normal"
                           onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                           onClickOnValidButton  = {closeAlertWindowWithValidButton1}
                           cancelButtonName      = "NON"
                           validButtonName       = "OUI"   /> )
	          */}
			
	          {/*
	            alertWindowVisible &&
		        authentication.token !== '' &&
	            ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être un abonné standard ou premium pour accéder à cette offre.</span><br/><br/>S'abonner ?<br/></> }
                           messageType           = "normal"
                           onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                           onClickOnValidButton  = {closeAlertWindowWithValidButton2}
                           cancelButtonName      = "NON"
                           validButtonName       = "OUI"   /> )
	          */}
			  
	          {
	            alertWindowVisible &&
		        authentication.token === '' &&
	            ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez disposer d’un compte gratuit</span><br/></> }
                           messageType           = "normal"
                           onClickOnCancelButton = {closeAlertWindowWithLoginButton}
                           onClickOnValidButton  = {closeAlertWindowWithCreateAccountButton}
                           cancelButtonName      = "Se connecter"
                           validButtonName       = "Créer un compte gratuit"   /> )
	          }
			  
		
			  {
	           alertWindowDeprecatedTokenVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre session a expiré, vous devez vous reconnecter.</span><br/><br/>Confirmer ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowDeprecatedTokenWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowDeprecatedTokenWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	          }
			  
			  {
	            alertInformationRequestWindowVisible &&
	            ( < WindowWithForm  message               = { <><span className = {classNames(styles.bold_text)}>Je souhaite recevoir par mail des information concernant l'offre "Urban Tour {destination}".</span><br/></> }
                                    messageType           = "normal"
                                    onClickOnCancelButton = {closeAlertInformationRequestWindowWithCancelButton}
                                    onClickOnValidButton  = {closeAlertInformationRequestWindowWithValidButton}
                                    cancelButtonName      = "NON"
                                    validButtonName       = "OUI"
							        stylesGridInfo        = {classNames(styles.grid_info)} 
									initialFirstname      = {user.firstname}
								    initialLastname       = {user.lastname}
								    initialAge            = {calculateAge()}
								    initialEmail          = {user.email}
								    initialPhoneNumber    = {user.phoneNumber}
								    initialCity           = {user.city}  /> )
	          }

           <div className={classNames(styles.root)}>


			 { 
			  ( isSmallScreen ? renderSmallSizeComponent() : renderBigSizeComponent () ) 
			 }


           </div>

			<Footer/>

            </>
           
         );
};


export default UrbanTour_Registration;
