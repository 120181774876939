import   React       ,
       { useEffect   ,
	     useState    }  from 'react';

import   classNames     from 'classnames';

import { Link                        ,
         useLocation                 ,
         useNavigate                 }  from 'react-router-dom';

import { Dispatch                    }  from 'redux';
import { useDispatch                 }  from 'react-redux';

import { useTypedSelector            }  from '../../../datas/useTypeSelector';

import { initAuthentication          ,
         setLogin                    ,
         setToken                    ,
		 countAttempt                }  from '../../../datas/authenticationManagment/actionCreators';

import { Validation                  ,
         checkEmail                  ,
         checkPassword               }  from '../../../datas/authenticationManagment/validators';


import { setUser             ,
         addPayment                  }  from '../../../datas/userManagment/actionCreators';

import { setCatalog                  }  from '../../../datas/videoManagment/actionCreators';

import { setProductCatalog           }  from '../../../datas/productManagment/actionCreators';

import { setCart                     }  from '../../../datas/cartManagment/actionCreators';

import   styles         from './window_login.module.scss';

import { Field       }  from '../field/field';
import { WidgetFrame }  from '../widget_frame/widget_frame';
import { Trigger     }  from '../trigger/trigger';
import { WindowForgottenPassword     }  from '../../page/login/windows/forgotten_password/forgotten_password';

import { postUserAuthentication      ,
         getUserInfos                ,
         getUserEmailValidation      ,
         getUserEmailValidationCheck }  from '../../../network/api_user';

import { getCatalogInfos             }  from '../../../network/api_video';
import { getProductCatalogInfos      }  from '../../../network/api_product';

import { mergeWithUserCart           ,
         getCart                     }  from '../../../network/api_cart';

import { getPaymentInfos             ,
         getDetailledPaymentInfos    }  from '../../../network/api_payment';

import { postBannedIpAddress         ,
         getBannedIpAddressCheck     }  from '../../../network/api_banned_ip_addresses';

import { INTERNAL_LINKS              }  from '../../../network/urls';

import { postEvent                   }  from '../../../network/api_events_stats';

interface UserAuthenticationResult {

    userId           : number,
    userToken        : string,
    code             : number,
    message          : string,
    numberOfAttempts : number
  }

export interface WindowLoginProps {
  message:                 JSX.Element
  email:                   string
  onClickOnCancelButton?:  (() => void) | undefined;
  onClickOnValidButton?:   ((result: UserAuthenticationResult) => void) | undefined;
  cancelButtonName?:       string;
  validButtonName?:        string;
  stylesGridInfo:          string;
  eyeColor?:      string;
  eyeVisible?:    boolean | undefined;
  makeAuthentication: (userEmail: string, userPassword: string) => Promise<UserAuthenticationResult>;

}



export const WindowLogin = ({ message               ,
                              email               ,
                              onClickOnCancelButton ,
						      onClickOnValidButton  ,
						      cancelButtonName      ,
                              validButtonName       ,
							  stylesGridInfo        ,
                              eyeColor              ,
					          eyeVisible            ,
                              makeAuthentication    }: WindowLoginProps) => {

  const   dispatch: Dispatch<any> = useDispatch();
  const   navigate                = useNavigate();
  const   location                = useLocation();

  const { authentication }        = useTypedSelector((state) => state.authenticationManagment );
  const { cart           }        = useTypedSelector((state) => state.cartManagment );

  const [ password              , setPassword              ] = useState('');
  const [ messagePassword       , setMessagePassword       ] = useState ('');

  const [ showForgottenPassword , setForgottenPassword     ] = useState(false);
  const [ showErrorMessage      , setShowErrorMessage      ] = useState(0);
  const [ showEmailCheckMessage , setShowEmailCheckMessage ] = useState(0);
  const [ showBanMessage        , setShowBanMessage        ] = useState(false);

  const [ emailCheckDate        , setEmailCheckDate        ] = useState('');
  const [ emailCheck            , setEmailCheck            ] = useState(false);

  const [ version               , setVersion               ] = useState(0);

  const [ showWaitMessage       , setShowWaitMessage       ] = useState (false);

  const [ token , setToken ] = useState('');
  const [ userId , setUserId ] = useState(0);
  const [ numberOfAttempts , setNumberOfAttempts ] = useState(0);


  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword ( e.target.value );
    const retour: Validation = checkPassword ( e.target.value )
    setMessagePassword ( retour.comment );
  };

  const handleClickOnForgottenPassword = (event: React.MouseEvent<HTMLElement>) => {

    setForgottenPassword( true );
  };

  const handleClickOnCloseForgottenPassword = () => {

    setForgottenPassword( false );
  };

  const handleClickOnConnection = () => {

    setShowWaitMessage (true);
    makeAuthentication (email, password);
  }

  const checkAll = async (): Promise<boolean> => {

    const retour02 = checkPassword ( password )

    setMessagePassword    ( retour02.comment );

    const code:number = retour02.code;

    return ( code === 0 );
  }



  const handleCancelButtonClick = () => {

    if ( typeof (onClickOnCancelButton) !== "undefined" )
	 {
      onClickOnCancelButton ();
	 }
  };

  const handleValidButtonClick = () => {
    make ()
  };

  const make = async  () => {

    setShowWaitMessage (true);
    const result = await makeAuthentication (email, password);
    setShowWaitMessage (false);

    console.log ("handleValidButtonClick ok")

	setShowWaitMessage (false);
    setNumberOfAttempts (result.numberOfAttempts)

    switch ( result.code )
     {
      case 1 :  if ( typeof (onClickOnValidButton) !== "undefined" )
 	             {
                  console.log ("handleValidButtonClick ok2")

                  onClickOnValidButton ( result );
	             }

                break;

      case -1 : setShowErrorMessage ( 1 );

                break;

      default:  console.log ("Cas non géré", result.code)
     }
  }

  return (
            <div className= {classNames(styles.window)}>

              <div className = {classNames(styles.WhiteMessage,styles.message)}>{message}</div>

              <br/>

              <WidgetFrame name               = "Mot de passe"
                           error              = { messagePassword }
					       labelTextPosition  = "left"
						   labelBlockPosition = "top"
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "password"
                            placeholder    = ""
                            value          = {password}
                            onChange       = {handlePasswordChange}
                            eyeColor       = {eyeColor}
                            eyeVisible     = {eyeVisible} />

              </WidgetFrame>

              <p className = {classNames(styles.forgotten_password)} onClick = {handleClickOnForgottenPassword} >
                Mot de passe oublié
              </p>

              <br/>

              <div className={classNames(styles.buttons_block)}>

              {
			    ( typeof (onClickOnCancelButton) !== "undefined" ) &&
                (

                        <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleCancelButtonClick}>
                           { typeof (cancelButtonName) === "undefined" ? "Annuler" : cancelButtonName }
                        </button>

				)
			  }

              {
			    ( typeof (onClickOnValidButton) !== "undefined" ) &&
                (
                        <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleValidButtonClick}>
                          { typeof (validButtonName) === "undefined" ? "Valider" : validButtonName }
                        </button>

				)
			  }

    {
      showForgottenPassword &&
      ( <WindowForgottenPassword onClickOnCloseButton = {handleClickOnCloseForgottenPassword }/> )
    }


      {
        ( showErrorMessage === 1 ) &&
        (
		  <div>
           <p className = {classNames(styles.message_error)}>
            Erreur d'authentification.<br/>
            Tentative {numberOfAttempts} / 5
           </p>
          </div>
		)
      }

      {
        ( showErrorMessage === 2 ) &&
        (
		  <div>
           <p className = {classNames(styles.message_error)}>
            Vous devez valider votre adresse e-mail avant de pouvoir accéder à votre compte.<br/>
			Tentative {numberOfAttempts} / 5
           </p>
          </div>
		)
      }

      {
        ( showErrorMessage === 3 ) &&
        (
		  <div>
           <p className = {classNames(styles.message_error)}>
            Impossible de récupérer les informations de votre compte.<br/>
			Tentative {numberOfAttempts} / 5
           </p>
          </div>
		)
      }

      {
        ( showErrorMessage === 4 || showBanMessage ) &&
        (
		  <div>
           <p className = {classNames(styles.message_error)}>
            Trop de tentatives, votre compte est bloqué pendant 1 heure.
           </p>
          </div>
		)
      }

      {
        ( showErrorMessage === 5 ) &&
        (
		  <div>
           <p className = {classNames(styles.message_error)}>
            Le lien de validation de votre adresse email est obsolète, vous devez recréer votre compte.<br/>
           </p>
          </div>
		)
      }

      {
        ( showErrorMessage === 6 ) &&
        (
		  <div>
           <p className = {classNames(styles.message_error)}>
            Impossible de valider votre adresse email. <br/>
			Tentative {numberOfAttempts} / 5
           </p>
          </div>
		)
      }

      {
        ( showErrorMessage === 7 ) &&
        (
		  <div>
           <p className = {classNames(styles.message_error)}>
            Erreur de connexion au serveur. <br/>
			Tentative {numberOfAttempts} / 5
           </p>
          </div>
		)
      }

      {
        ( showWaitMessage ) &&
        (
		  <div>
           <p className = {classNames(styles.message_wait)}>
            Veuillez patienter un instant...
           </p>
          </div>
		)
      }


			 </div>

            </div>
         );
};

export default WindowLogin;
