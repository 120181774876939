import   React       ,
       { useEffect   ,
	     useState    }  from 'react';

import   classNames     from 'classnames';

import   styles         from './window.module.scss';


import { Validation            ,
         checkPassword          ,
         checkPassword2         ,
         checkRgpdConsent      }  from '../../../datas/userManagment/validators';

import { Field                 }  from '../field/field';
import { Select                }  from '../select/select';
import { DateSelector          }  from '../date_selector/date_selector';
import { WidgetFrame           }  from '../widget_frame/widget_frame';
import { Window                }  from '../window/window';
import { WidgetFrameLabel      }  from '../widget_frame/widget_frame_label';
import { Toggle                }  from '../toggle/toggle';
import { TextArea              }  from '../textarea/textarea';
import { AnimatedWaitingIcon   }  from '../animated_waiting_icon/animated_waiting_icon';


export interface WindowWithUserCreationFormProps {
  email:                   string
  onClickOnCancelButton?:  (() => void) | undefined;
  onClickOnValidButton?:   ((password: string) => void) | undefined;
  stylesGridInfo?:         string;
  eyeColor?:               string;
  eyeVisible?:             boolean | undefined;
}

export const WindowWithUserCreationForm = ({ email                 ,
                                             onClickOnCancelButton ,
						                     onClickOnValidButton  ,
							                 stylesGridInfo        ,
                                             eyeColor              ,
					                         eyeVisible            }: WindowWithUserCreationFormProps) => {

  const [ password           , setPassword           ] = useState ( '' );
  const [ password2          , setPassword2          ] = useState ( '' );
  const [ rgpdConsent        , setRgpdConsent        ] = useState ( false );

  const [ messagePassword    , setMessagePassword    ] = useState ( "" );
  const [ messagePassword2   , setMessagePassword2   ] = useState ( "" );
  const [ messageRgpdConsent , setMessageRgpdConsent ] = useState ( "" );

  const [ showForgottenPassword , setForgottenPassword     ] = useState(false);

  const [ isSmallScreen      , setIsSmallScreen      ] = useState (false);

  const handleResize = () => {
      setIsSmallScreen ( window.innerWidth <= 600 );
  };

  useEffect(() => {

    console.log ('WindowWithUserCreationForm ', eyeColor, eyeVisible)

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);


  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword ( e.target.value )

    const response: Validation = checkPassword ( e.target.value )
    setMessagePassword ( response.comment );

    const retour2: Validation = checkPassword2 ( password2, e.target.value )
    setMessagePassword2 ( retour2.comment );
  };

  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword2    ( e.target.value )

    const retour: Validation = checkPassword2 ( e.target.value , password )
    setMessagePassword2 ( retour.comment );
  };

  const handleRgpdConsentChange  = ( newRgpdConsent: boolean ) => {

    setRgpdConsent ( newRgpdConsent )
   // dispatch ( updateRgpdConsent ( newRgpdConsent ) )

    const response: Validation = checkRgpdConsent ( newRgpdConsent )
    setMessageRgpdConsent ( response.comment );
  };


  const handleCancelButtonClick = () => {

    if ( typeof (onClickOnCancelButton) !== "undefined" )
	 {
      onClickOnCancelButton ();
	 }
  };

  const handleValidButtonClick = () => {

    if ( typeof (onClickOnValidButton) !== "undefined" )
	 {
      const checkPasswordResult    = checkPassword    ( password )
      const checkPassword2Result   = checkPassword2   ( password2 , password )
	  const checkRgpdConsentResult = checkRgpdConsent ( rgpdConsent )

	  setMessagePassword    ( checkPasswordResult.comment );
	  setMessagePassword2   ( checkPassword2Result.comment );
	  setMessageRgpdConsent ( checkRgpdConsentResult.comment );

      if ( checkPasswordResult.code    +
           checkPassword2Result.code   +
           checkRgpdConsentResult.code === 3 )
       {
        onClickOnValidButton (password)
       }
    }
  };

  return (
           <div className= {classNames(styles.window)}>

               <div className = {classNames(styles.WhiteMessage,styles.message)}>
                  Nous allons créer votre compte pour l'adresse {email}. Veuillez saisir un mot de passe
               </div>

               <br/>

               <WidgetFrame name               = "Mot de passe"
                           error              = {messagePassword}
                           labelTextPosition  = "left"
                           labelBlockPosition = "top"   >

                     <Field type           = "password"
                            placeholder    = ""
                            value          = {password}
                            onChange       = {handlePasswordChange}
                            eyeColor       = {eyeColor}
                            eyeVisible     = {eyeVisible} />

               </WidgetFrame>

               <div className={styles.interzone}>&nbsp;</div>

               <WidgetFrame name               = "Confirmation du mot de passe"
                            error              = {messagePassword2}
                            labelTextPosition  = "left"
                            labelBlockPosition = "top"   >

                     <Field type           = "password"
                            placeholder    = ""
                            value          = {password2}
                            onChange       = {handlePassword2Change}
                            eyeColor       = {eyeColor}
                            eyeVisible     = {eyeVisible} />

               </WidgetFrame>

               <div className={styles.interzone}>&nbsp;</div>

               <WidgetFrame error              = {messageRgpdConsent}
                            classNameGrid      = {classNames(styles.grid_rgpdConsent)}
                            labelBlockPosition = "right" >

                   <WidgetFrameLabel className = {classNames(styles.text_rgpdConsent)}>

                       En envoyant ce formulaire, j'accepte également les Conditions d’utilisation du site Internet. Nous traitons vos données personnelles conformément à notre Politique de confidentialité Lovelight TV. Vous avez le droit de refuser certaines activités de traitement de vos données personnelles. Consultez la section « Vos droits » de la Politique de confidentialité pour en savoir plus.

                   </WidgetFrameLabel>

                   <Toggle checked        = {rgpdConsent}
                           onChange       = {handleRgpdConsentChange}
                           form           = "checkbox" />

               </WidgetFrame>

               <br/>

               <div className={classNames(styles.buttons_block)}>

                   {
			         ( typeof (onClickOnCancelButton) !== "undefined" ) &&
                     (

                        <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleCancelButtonClick}>
                           Annuler
                        </button>
				     )
			       }

                   {
			         ( typeof (onClickOnValidButton) !== "undefined" ) &&
                     (
                        <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleValidButtonClick}>
                          Je finalise ma préinscription
                        </button>
				     )
			       }

			   </div>

           </div>
         );
};

export default WindowWithUserCreationForm;
